import Api from './api';

export default {
  // Public routes
  getAllForums: () => Api.get('forums'),
  getForumDetails: (forumSlug) => Api.get(`forums/${forumSlug}`),
  getTopics: (forumSlug, page) => Api.get(`forums/${forumSlug}/topics?page=${page}`),
  getTopicDetails: (forumSlug, topicId) => Api.get(`forums/${forumSlug}/topics/${topicId}`),
  getTopicComments: (forumSlug, topicId, page) => Api.get(`forums/${forumSlug}/topics/${topicId}/comments?page=${page}`),
  search: (keyword) => Api.get(`forums/search?q=${keyword}`),
  subscribeForum: (forumSlug) => Api.post(`forums/${forumSlug}/subscribe`),
  subscribeTopic: (forumSlug, topicId) => Api.post(`forums/${forumSlug}/topics/${topicId}/subscribe`),
  deleteTopic: (topicId) => Api.delete(`forums/topics/${topicId}`),
  deleteComment: (commentId) => Api.delete(`forums/comments/${commentId}`),

  // Auth routes
  createTopic: (forumSlug, title, text, subscribe, attachments) => {
    const formData = new FormData();

    formData.append('subscribe', subscribe);
    formData.append('title', title);
    formData.append('text', text);

    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment);
    });

    return Api.formDataPost(`forums/${forumSlug}/topics`, formData);
  },

  createComment: (forumSlug, topicId, text, subscribe, attachments) => {
    const formData = new FormData();

    formData.append('subscribe', subscribe);
    formData.append('text', text);

    attachments.forEach((attachment) => {
      formData.append('attachments[]', attachment);
    });

    return Api.formDataPost(`forums/${forumSlug}/topics/${topicId}/comments`, formData);
  },
};
