<template>
  <div
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :class="`is-relative ${hasBorder ? 'custom-panel' : ''}`"
  >
    <b-loading :is-full-page="false" v-model="loadingDelete"></b-loading>
    <a v-if="hover && canDeletePost" @click="deletePost" class="delete-icon delete-icon-post">
      <b-icon icon="times" />
    </a>
    <div class="feed-item-container">
      <div class="feed-item-avatar has-text-centered mr-4">
        <div class="has-text-centered" v-if="singleProfile && (hasPopupImage || hasIcon)">
          <PoppableItemImage
            v-if="hasPopupImage"
            :type="feedItem.type"
            :id="feedItem.object_id"
            :label="feedItem.object_name"
            size="x-small"
          />
          <div v-if="hasIcon">
            <b-icon :icon="itemIcon" class="feed-item-icon"></b-icon>
          </div>
        </div>
        <username v-else-if="feedItem.type !== 'start_game'" :show-name="false" :user="feedItem.user" />
      </div>
      <div class="feed-item-content">
        <div>
          <div v-if="feedItem.type === 'post'">
            <username :show-avatar="false" :user="feedItem.user" /> said:
            <div class="mt-3">{{ feedItem.text }}</div>
          </div>

          <div v-else-if="feedItem.type === 'game_activity'">
          </div>

          <div v-else-if="feedItem.type === 'snap'">
          </div>

          <div v-else-if="feedItem.type === 'skill'">
            <username :show-avatar="false" :user="feedItem.user" /> learned the
            "<PoppableItemText :type="feedItem.type" :id="feedItem.object_id" :text="feedItem.object_name" />"
            skill
          </div>

          <div v-else-if="feedItem.type === 'achievement'">
            <username :show-avatar="false" :user="feedItem.user" /> earned the
            "<PoppableItemText :type="feedItem.type" :id="feedItem.object_id" :text="feedItem.object_name" />"
            achievement
          </div>

          <div v-else-if="feedItem.type === 'upgrade'">
            <username :show-avatar="false" :user="feedItem.user" /> bought the
            "<PoppableItemText :type="feedItem.type" :id="feedItem.object_id" :text="feedItem.object_name" />"
            upgrade
          </div>

          <div v-else-if="feedItem.type === 'forum_topic'">
            <username :show-avatar="false" :user="feedItem.user" /> posted
            "<PoppableItemText type="forum_topic" :id="stringFeedItemId" :text="feedItem.text" :object_id="feedItem.object_id" />"
            topic in
            "<PoppableItemText type="forum_forum" :id="feedItem.object_id" :text="feedItem.object_name" />"
            forums
          </div>

          <div v-else-if="feedItem.type === 'forum_comment'">
            <username :show-avatar="false" :user="feedItem.user" /> posted a comment in
            "<PoppableItemText type="forum_topic" :id="stringFeedItemId" :text="feedItem.text" :object_id="feedItem.object_id" />"
            in
            "<PoppableItemText type="forum_forum" :id="feedItem.object_id" :text="feedItem.object_name" />"
            forums
          </div>

          <div v-else-if="feedItem.type === 'start'">
            <username :show-avatar="false" :user="feedItem.user" /> started playing Odd Giants
          </div>

          <div v-else-if="feedItem.type === 'start_game'">
            The BIG BANG when Odd Giants started!
          </div>
        </div>
        <div class="mt-1">
          <forum-date :date="feedItem.created_at" icon />
        </div>
      </div>
      <div class="feed-item-object-image has-text-centered ml-4" v-if="!singleProfile && (hasPopupImage || hasIcon)">
        <PoppableItemImage
          v-if="hasPopupImage"
          :type="feedItem.type"
          :id="feedItem.object_id"
          :label="feedItem.object_name"
          size="x-small"
        />
        <div v-if="hasIcon">
          <b-icon :icon="itemIcon" class="feed-item-icon"></b-icon>
        </div>
      </div>
    </div>
    <div v-if="feedItem.commentable" class="mt-3 pt-2" style="border-top: 1px solid #ededed;">
      <div class="columns counters" >
        <div class="column">
          <div class="counter-container">
            <b-tooltip
              :label="feedItem.liked ? 'I don\'t like it anymore :(' : 'I like this :)'"
              type="is-dark"
              position="is-top"
            >
              <span @click="toggleLike">
                <b-icon
                  icon="heart"
                  :class="feedItem.liked ? 'dislike-link' : 'like-link'"
                />
              </span>
            </b-tooltip>
            <span @click="showLikes" class="social-counter-link social-counter-like-link">
              {{ likeText }}
            </span>
          </div>
        </div>
        <div class="column has-text-right">
          <span @click="showComments" class="social-counter-link social-counter-comment-link">
            <b-tooltip
              :label="isAuthenticated ? 'Add a comment' : 'View comments'"
              type="is-dark"
              position="is-top"
            >
              <div class="counter-container">
                <span>{{ feedItem.totalComments }} comments</span>
                <b-icon icon="comment" class="comment-link" />
              </div>
            </b-tooltip>
          </span>
        </div>
      </div>
      <div v-if="commentsShown" class="custom-panel custom-panel-grey ml-6 is-relative">
        <b-loading :is-full-page="false" v-model="loadingComments"></b-loading>
        <div v-if="comments.length">
          <div v-if="!noMoreComments" class="has-text-centered mb-4">
            <a @click="loadMoreComments">View older comments</a>
          </div>
          <div
            class="comment-container is-relative mt-3"
            v-for="comment in comments"
            :key="comment.id"
            @mouseover="setHoverComment(comment.id, true)"
            @mouseleave="setHoverComment(comment.id, false)"
          >
            <b-loading :is-full-page="false" v-model="loadingDeleteComment"></b-loading>
            <a v-if="hoverComment === comment.id && canDeleteComment(comment)" @click="deleteComment(comment)" class="delete-icon delete-icon-post">
              <b-icon icon="times" />
            </a>
            <div class="comment-avatar mr-4">
              <username :user="comment.user" size="small-circle" :show-name="false"/>
            </div>
            <div class="comment-content">
              <username :show-avatar="false" :user="comment.user" /> said:
              <div v-html="comment.text"></div>
              <forum-date class="mt-1" :date="comment.created_at" icon />
            </div>
          </div>
        </div>
        <div v-if="isAuthenticated">
          <div class="comment-container comment-container-new mt-3">
            <div class="comment-avatar mr-4">
              <username :user="profile" :show-name="false" size="small-circle" />
            </div>
            <div class="comment-content has-text-right">
              <ResizableTextarea
                expanded
                rows="2"
                v-model="newComment"
                type="textarea"
                minlength="5"
                placeholder="Your thoughts on this?"
                size="is-small"
                style="height: auto;"
              />
            </div>
            <b-button @click="addComment" :loading="loadingAddComment" size="is-small" type="is-primary" class="ml-2">Add</b-button>
          </div>
        </div>
        <div v-else class="has-text-centered mt-5">
          Please <a @click="showLogin">Sign in</a> to join the discussion.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { feedItemHasIcon, feedItemHasPopupImage, feedItemIcon } from '@/services/feed';
import { Social } from '@/services/api';
import { mapGetters } from 'vuex';
import Username from '../Forum/Username.vue';
import ForumDate from '../Forum/ForumDate.vue';
import PoppableItemImage from './PoppableItemImage.vue';
import PoppableItemText from './PoppableItemText.vue';
import ResizableTextarea from '../Common/ResizableTextArea.vue';

export default {
  name: 'feed-item',
  components: {
    ResizableTextarea,
    PoppableItemText,
    PoppableItemImage,
    ForumDate,
    Username,
  },
  props: {
    feedItem: Object,
    showAvatar: Boolean,
    commentsOnLoad: {
      type: Boolean,
      default: false,
    },
    singleProfile: Boolean,
  },
  data() {
    return {
      hover: false,
      hoverComment: null,
      commentsShown: false,
      comments: [],
      newComment: null,
      loadingAddComment: false,
      loadingDeleteComment: false,
      loadingDelete: false,
      loadingComments: false,
      noMoreComments: false,
    };
  },
  watch: {
    feedItem: {
      handler(newFeedItem, oldFeedItem) {
        if (this.commentsOnLoad && `${newFeedItem.type}-${newFeedItem.id}` !== `${oldFeedItem.type}-${oldFeedItem.id}`) {
          this.comments = [];
          this.refreshComments();
        }
      },
    },
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated', 'profile']),
    stringFeedItemId() {
      return this.feedItem.id.toString();
    },
    canDeletePost() {
      return this.feedItem.type === 'post' && this.isAuthenticated && this.feedItem.user_id === this.profile.id;
    },
    hasBorder() {
      return !this.singleProfile || this.feedItem.commentable;
    },
    hasIcon() {
      return feedItemHasIcon(this.feedItem.type);
    },
    hasPopupImage() {
      return feedItemHasPopupImage(this.feedItem.type);
    },
    itemIcon() {
      return feedItemIcon(this.feedItem.type);
    },
    likeText() {
      if (!this.feedItem.liked) {
        return `${this.feedItem.totalLikes} like this`;
      }

      const otherLikes = this.feedItem.totalLikes - 1;

      return `You${otherLikes ? ` and ${otherLikes} others` : ''} like this`;
    },
  },
  mounted() {
    if (this.commentsOnLoad) {
      this.showComments();
    }
  },
  methods: {
    showLogin() {
      this.$store.dispatch('global/toggleLoginModal', true);
    },
    showLikes() {
      if (this.feedItem.totalLikes === 0) {
        return;
      }

      this.$store.dispatch('global/showLikes', this.feedItem);
    },
    toggleLike() {
      if (!this.isAuthenticated) {
        this.showLogin();
        return;
      }

      this.$store.dispatch('player/toggleLike', this.feedItem);
    },
    setHoverComment(id, hover) {
      this.hoverComment = hover ? id : null;
    },
    canDeleteComment(comment) {
      return this.isAuthenticated && comment.user_id === this.profile.id;
    },
    showComments() {
      if (this.feedItem.totalComments === 0 && !this.isAuthenticated) {
        this.showLogin();
      }

      this.commentsShown = !this.commentsShown;

      if (this.commentsShown) {
        this.refreshComments();
      }
    },
    loadMoreComments() {
      this.loadingComments = true;
      Social.getComments(this.feedItem.type, this.feedItem.id, this.comments.length)
        .then((data) => {
          if (!data || data.length === 0) {
            this.noMoreComments = true;
          } else {
            if (data.length < 10) {
              this.noMoreComments = true;
            }

            this.comments = [
              ...data,
              ...this.comments,
            ];
          }
        })
        .finally(() => {
          this.loadingComments = false;
        });
    },
    refreshComments() {
      this.loadingComments = true;
      Social.getComments(this.feedItem.type, this.feedItem.id, 0)
        .then((data) => {
          if (!data || data.length === 0) {
            this.noMoreComments = true;
          } else {
            if (data.length < 10) {
              this.noMoreComments = true;
            }

            if (this.comments.length === 0) {
              this.comments = data;

              return;
            }

            const lastComment = this.comments[this.comments.length - 1];
            const newComments = [];

            // eslint-disable-next-line no-plusplus
            for (let i = data.length - 1; i >= 0; i--) {
              if (data[i].id === lastComment.id) {
                break;
              }

              newComments.push(data[i]);
            }

            this.comments = [
              ...this.comments,
              ...newComments,
            ];
          }
        })
        .finally(() => {
          this.loadingComments = false;

          this.$store.dispatch('player/refreshFeedItem', {
            newItem: {
              ...this.feedItem,
              totalComments: this.comments.length,
            },
            oldItem: this.feedItem,
          });
        });
    },
    addComment() {
      this.loadingComment = true;
      Social.createComment(this.feedItem.type, this.feedItem.id, this.newComment)
        .then(() => {
          this.refreshComments();
        })
        .finally(() => {
          this.loadingComment = false;
          this.newComment = null;
        });
    },
    deleteComment(comment) {
      this.$buefy.dialog.confirm({
        title: 'Delete?',
        message: 'Are you sure you want to delete that comment?',
        confirmText: 'Yes, delete!',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.loadingDeleteComment = true;

          Social.deleteComment(comment.id)
            .then(() => {
              const newComments = [];

              this.comments.forEach((item) => {
                if (item.id !== comment.id) {
                  newComments.push(item);
                }
              });

              this.comments = newComments;

              this.refreshComments();
            })
            .catch((error) => {
              const responseError = error.response.data;

              this.$buefy.dialog.alert({
                title: 'Error',
                message: responseError.message || 'Sorry, something went wrong :( Please try again later...',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true,
              });
            })
            .finally(() => {
              this.loadingDeleteComment = false;
            });
        },
      });
    },
    deletePost() {
      this.$buefy.dialog.confirm({
        title: 'Delete?',
        message: 'Are you sure you want to delete that post?',
        confirmText: 'Yes, delete!',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.loadingDelete = true;

          Social.deletePost(this.feedItem.id)
            .then(() => {
              this.$store.dispatch('player/removeFeedItem', this.feedItem);
            })
            .catch((error) => {
              const responseError = error.response.data;

              this.$buefy.dialog.alert({
                title: 'Error',
                message: responseError.message || 'Sorry, something went wrong :( Please try again later...',
                type: 'is-danger',
                hasIcon: true,
                icon: 'times-circle',
                iconPack: 'fa',
                ariaRole: 'alertdialog',
                ariaModal: true,
              });
            })
            .finally(() => {
              this.loadingDelete = false;
            });
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/variables";
.delete-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #aaaaaa;

  &.delete-icon-post {
    font-size: 1rem;
  }
}
.comment-container {
  display: flex;
  border-bottom: 1px solid $grayLight;
  padding-bottom: 8px;

  .comment-avatar {
    min-width: 50px;
    max-width: 50px;
  }

  .comment-content {
    flex: 1;
  }

  &.comment-container-new {
    border-bottom: none;
  }
}

.counters {
  color: #aaa;

  .counter-container {
    display: flex;
    align-items: center;
  }

  .social-counter-link {
    cursor: pointer;

    &:hover, &:focus {
      color: $blueGray;
    }
  }

  .like-link {
    cursor: pointer;
    font-size: 1rem;
    border-right: 1px solid #d3d3d3;
    margin-right: 8px;
    padding-right: 8px;

    &:hover, &:focus {
      color: $like;
    }
  }

  .dislike-link {
    cursor: pointer;
    font-size: 1rem;
    color: $like;
    border-right: 1px solid #d3d3d3;
    margin-right: 8px;
    padding-right: 8px;

    &:hover, &:focus {
      color: #aaa;
    }
  }

  .comment-link {
    border-left: 1px solid #d3d3d3;
    margin-left: 8px;
    padding-left: 8px;
    font-size: 1rem;
  }

  .social-counter-like-link {
    &:hover, &:focus {
      .icon {
        color: $like;
      }
    }
  }
}
</style>
