import Api from './api';

export default {
  getAllUsersFeed: (available) => Api.get(`social/feed?available=${available}`),
  getUserFeed: (userId, available) => Api.get(`social/feed/${userId}?available=${available}`),
  getSingleFeedItem: (type, id) => Api.get(`social/feed/${type}/${id}`),
  createPost: (text) => Api.post('social/posts', { text }),
  deletePost: (id) => Api.delete('social/posts', { id }),
  createComment: (type, id, text) => Api.post('social/comments', { type, id, text }),
  deleteComment: (id) => Api.delete('social/comments', { id }),
  getComments: (type, id, available) => Api.get(`social/comments/${type}/${id}?available=${available}`),
  toggleLike: (type, id) => Api.post('social/likes', { type, id }),
  getLikes: (type, id) => Api.get(`social/likes/${type}/${id}`),
};
