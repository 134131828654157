import Vue from 'vue';
import Vuex from 'vuex';

import { encyclopedia } from './encyclopedia';
import { feat } from './feat';
import { forum } from './forum';
import { global } from './global';
import { player } from './player';
import { user } from './user';

Vue.use(Vuex);

// eslint-disable-next-line import/prefer-default-export
export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    initialize() {
      this.dispatch('user/initialize');
      this.dispatch('global/loadConfigs');
      this.dispatch('global/loadFeaturedPlayers');
      this.dispatch('encyclopedia/loadCategories');
    },
  },
  getters: {
    isInitializing(state, getters) {
      return getters['user/isLoadingProfile'] || getters['global/isLoadingConfigs'];
    },
  },
  modules: {
    encyclopedia,
    feat,
    forum,
    global,
    player,
    user,
  },
});
