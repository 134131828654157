<template>
  <div class="modal-card" style="width: auto">
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
    <section class="modal-card-body" v-if="likes">
      <h4 class="title is-4">Oddlings who liked that:</h4>
      <div class="likes-container">
        <div
          class="like-item"
          v-for="like in likes"
          :key="like.id"
        >
          <username :user="like.user"/>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { Social } from '@/services/api';
import Username from '../Forum/Username.vue';

export default {
  name: 'likes-modal',
  components: { Username },
  data() {
    return {
      loading: false,
      likes: [],
    };
  },
  computed: {
    ...mapState('global', ['likesItem']),
  },
  mounted() {
    this.loading = true;
    Social.getLikes(this.likesItem.type, this.likesItem.id)
      .then((data) => {
        this.likes = data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss">
.likes-container {
  display: flex;
  max-width: 100%;
  flex-flow: row wrap;
  max-height: 100%;

  .like-item {
    max-width: 100px;
    text-align: center;
    margin: 5px;
  }
}
</style>
