import { Forum } from '@/services/api';

// eslint-disable-next-line import/prefer-default-export
export const forum = {
  namespaced: true,
  state: {
    comments: [],
    forums: [],
    loadingForum: false,
    loadingForums: false,
    loadingComments: false,
    loadingTopic: false,
    loadingTopics: false,
    selectedForum: null,
    selectedTopic: null,
    topicFormForum: null,
    topics: [],
  },
  mutations: {
    setComments(state, comments) {
      state.comments = comments;
    },
    setForums(state, forums) {
      state.forums = forums;
    },
    setLoadingComments(state, loading) {
      state.loadingComments = loading;
    },
    setLoadingForum(state, loading) {
      state.loadingForum = loading;
    },
    setLoadingForums(state, loading) {
      state.loadingForums = loading;
    },
    setLoadingTopic(state, loading) {
      state.loadingTopic = loading;
    },
    setLoadingTopics(state, loading) {
      state.loadingTopics = loading;
    },
    setSelectedForum(state, selectedForum) {
      state.selectedForum = selectedForum;
    },
    setSelectedTopic(state, selectedTopic) {
      state.selectedTopic = selectedTopic;
    },
    setTopicFormForum(state, selectedForum) {
      state.topicFormForum = selectedForum;
    },
    setTopics(state, topics) {
      state.topics = topics;
    },
  },
  actions: {
    async loadForum({ commit, state }, forumSlug) {
      if (state.selectedForum && state.selectedForum.slug === forumSlug) {
        return;
      }

      commit('setLoadingForum', true);

      await Forum.getForumDetails(forumSlug)
        .then((data) => {
          commit('setSelectedForum', data);
        })
        .finally(() => {
          commit('setLoadingForum', false);
        });
    },
    loadForums({ commit, state }) {
      if (state.forums.length) {
        return new Promise(() => { });
      }

      commit('setLoadingForums', true);

      return Forum.getAllForums()
        .then((data) => {
          commit('setForums', data);
        })
        .finally(() => {
          commit('setLoadingForums', false);
        });
    },
    loadComments({ commit, state }, page) {
      commit('setLoadingComments', true);

      return Forum.getTopicComments(state.selectedForum.slug, state.selectedTopic.id, page)
        .then((data) => {
          commit('setComments', data);
        })
        .finally(() => {
          commit('setLoadingComments', false);
        });
    },
    loadTopic({ commit, state }, topicId) {
      if (state.selectedTopic && state.selectedTopic.id === topicId) {
        return new Promise(() => { });
      }

      commit('setLoadingTopic', true);

      return Forum.getTopicDetails(state.selectedForum.slug, topicId)
        .then((data) => {
          commit('setSelectedTopic', data);
        })
        .finally(() => {
          commit('setLoadingTopic', false);
        });
    },
    loadTopics({ commit, state }, page) {
      commit('setLoadingTopics', true);

      return Forum.getTopics(state.selectedForum.slug, page)
        .then((data) => {
          commit('setTopics', data);
        })
        .finally(() => {
          commit('setLoadingTopics', false);
        });
    },
    setTopicFormForum({ commit }, forumSlug) {
      commit('setTopicFormForum', forumSlug);
    },
    unselectForum({ commit }) {
      commit('setSelectedForum', null);
    },
  },
  getters: {
    isBugsSelected(state) {
      return state.selectedForum && state.selectedForum.slug === 'bugs';
    },
    isAnnouncements(state) {
      return state.selectedForum && state.selectedForum.slug === 'announcements';
    },
  },
};
