<template>
  <div :class="`${inlineThumb ? 'image-inline' : ''} ${size} item-image-container ${bordered ? 'item-image-bordered' : ''}`">
    <div class="item-link">
      <b-tooltip
        :label="label"
        type="is-dark"
        position="is-top"
      >
        <img :src="imageSource" @click="showPopup" />
      </b-tooltip>

      <div v-if="stringBadge" class="badge counter-label">{{ stringBadge }}</div>
      <b-icon v-if="iconBadge" pack="fas" :icon="iconBadge" size="is-small" class="badge" type="is-danger"></b-icon>
    </div>
  </div>
</template>

<script>
import { getEncyclopediaImageUrl } from '@/services/encyclopedia';

export default {
  name: 'poppable-item-image',
  props: {
    bordered: {
      type: Boolean,
      default: false,
    },
    thumb: Boolean,
    inlineThumb: Boolean,
    type: String,
    id: String,
    label: String,
    badge: String,
    size: String,
  },
  watch: {
    badge: {
      immediate: true,
      handler(val) {
        if (val) {
          const badge = val.toString();
          if (badge.includes('icon/')) {
            // eslint-disable-next-line prefer-destructuring
            this.iconBadge = badge.split('/')[1];
          } else {
            this.stringBadge = badge;
          }
        }
      },
    },
  },
  data() {
    return {
      stringBadge: null,
      iconBadge: null,
    };
  },
  computed: {
    imageSource() {
      return getEncyclopediaImageUrl(`${this.type}s`, this.id);
    },
  },
  methods: {
    showPopup() {
      this.$store.dispatch('global/showPopupItem', {
        type: this.type,
        id: this.id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.item-image-container {
  width: 60px;
  margin: 4px;
  padding: 4px;
  text-align: center;
  vertical-align: middle;
  position: relative;
  cursor: pointer;

  &.item-image-bordered {
    border: 1px solid #f4f4f4;
    border-radius: 4px;

    &:hover {
      border-color: #d4d4d4;
    }
  }

  .item-link {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }

  &.x-small {
    height: 40px;
    width: auto;

    img {
      max-height: 40px;
    }
  }

  &.small {
    width: 60px;
  }

  &.medium {
    width: 90px;
  }

  .badge {
    position: absolute;
    bottom: 0;
    right: 0;

    &.counter-label {
      background-color: #ddd;
      padding: 4px;
      border-radius: 30px;
      font-size: 10px;
      font-weight: bold;
    }
  }
}

.image-inline {
  display: inline-block;

  > img {
    width: 16px;
    height: 16px;
    margin-left: 9px;
    margin-right: 3px;
  }
}
</style>
