<template>
  <div :class="`notification-container ${popup ? 'popup-notification' : 'page-notification'}`">
    <div class="notification-avatar mr-4">
      <username :user="notification.action_user" :show-name="false" />
    </div>
    <div class="notification-content">
      <strong>{{ notification.action_user.name }}</strong> {{ actionText }} "{{ notification.notifiable.text | excerpt(excerptLimit) }}"
      <br />
      <forum-date :date="notification.created_at" icon />
    </div>
  </div>
</template>

<script>
import Username from '@/components/Forum/Username.vue';
import ForumDate from '@/components/Forum/ForumDate.vue';

export default {
  name: 'Notification',
  components: { ForumDate, Username },
  props: {
    notification: Object,
    popup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    actionText() {
      switch (this.notification.action_type) {
        case 'notification_comments':
          return 'commented on your post';
        case 'notification_likes':
          return 'liked your post';
        case 'notification_comments_reply':
          return 'commented on a post you have commented';
        default:
          return '';
      }
    },
    excerptLimit() {
      return this.popup ? 30 : 100;
    },
  },
};
</script>

<style scoped lang="scss">
.notification-container {
  display: flex;

  .notification-avatar {
    min-width: 50px;
    max-width: 50px;
  }

  .notification-content {
    white-space: normal;
    word-break: break-word;
  }
}

.popup-notification {
  .notification-content {
    width: 250px;
  }
}

.page-notification {
  .notification-content {
    width: 100%;
  }
}
</style>
