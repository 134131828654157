<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">Sign up</p>
      <button
        type="button"
        class="delete"
        @click="close"/>
    </header>
    <div class="modal-card" style="width: auto">
      <section class="modal-card-body">
        <div v-if="!registered">
          <b-notification
            v-if="errors.length"
            type="is-danger"
            aria-close-label="Close"
            role="alert"
          >
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </b-notification>

          <form @submit="handleSignup">
            <b-field label="Email" message="Use your email to log in" custom-class="is-medium">
              <b-input type="email" v-model="formData.email" size="is-medium"></b-input>
            </b-field>

            <b-field label="Screen name" message="Shown in-game above your avatar" custom-class="is-medium">
              <b-input v-model="formData.name" size="is-medium"></b-input>
            </b-field>

            <b-field label="Password" custom-class="is-medium">
              <b-input type="password" size="is-medium" v-model="formData.password" password-reveal></b-input>
            </b-field>

            <b-field>
              <b-checkbox v-model="formData.subscribe">Get notifications by email when we have exciting news</b-checkbox>
            </b-field>

            <b-field>
              <b-checkbox v-model="formData.terms">I have read and accept the <a href="https://oddgiants.com/terms-and-conditions" target="_blank">Terms and Conditions</a> and the <a href="https://oddgiants.com/privacy-policy" target="_blank">Privacy Policy</a> of Odd Giants</b-checkbox>
            </b-field>

            <div class="columns is-mobile">
              <div class="column">
                <b-button type="is-primary" native-type="submit" size="is-medium" :disabled="!formData.terms">Sign up</b-button>
              </div>
              <div class="column">
                <div class="has-text-weight-bold has-text-right mt-3"><a @click="showLogin">Already have an account? Sign in!</a></div>
              </div>
            </div>
          </form>

          <b-loading :is-full-page="false" v-model="loading"></b-loading>
        </div>
        <div v-else class="has-text-centered">
          <div class="title has-text-primary">
            Thank you!
          </div>

          <p>Thank you for signing up for <strong>Odd Giants</strong>! You won't regret it!</p>

          <p class="mt-4">
            You can download the alpha version
            <strong><a class="has-text-primary" @click="goToDownloads">from our DOWNLOADS PAGE</a></strong>
          </p>

          <p class="has-text-weight-bold">ENJOY!</p>

          <p class="mt-4">Let us know what you think from the contact form.</p>

          <p class="has-text-centered has-text-danger has-text-weight-bold mt-4">If you like what we're doing and want to support us, so we can keep moving, you can become our patron on <a href="https://www.patreon.com/oddgiants" target="_blank">PATREON</a></p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { User } from '@/services/api';

export default {
  name: 'signup',
  data() {
    return {
      errors: [],
      formData: {
        email: null,
        name: null,
        subscribe: true,
        terms: false,
        password: null,
      },
      loading: false,
      registered: false,
    };
  },
  methods: {
    handleSignup(event) {
      event.preventDefault();

      this.errors = [];
      this.loading = true;

      User.addProfile(this.formData)
        .then(() => {
          this.registered = true;
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$store.dispatch('global/toggleSignupModal', false);
    },
    goToDownloads() {
      this.close();
      this.$router.push({ path: '/downloads' });
    },
    showLogin() {
      this.close();
      this.$store.dispatch('global/toggleLoginModal', true);
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  color: #888;
  font-style: italic;
  font-size: 1rem;
}
</style>
