export const feedItemHasPopupImage = (type) => ['skill', 'upgrade', 'achievement'].includes(type);

export const feedItemHasIcon = (type) => ['forum_forum', 'forum_topic', 'forum_comment'].includes(type);

export const feedItemIcon = (type) => {
  switch (type) {
    case 'forum_forum':
    case 'forum_topic':
      return 'bullhorn';
    default:
      return 'comments';
  }
};
