var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('section',{staticClass:"modal-card-body"},[(!_vm.sent)?_c('div',[_c('div',[_c('h4',{staticClass:"title is-5"},[_vm._v("What do you want to discuss?")]),(_vm.errors.length)?_c('b-notification',{attrs:{"type":"is-danger","aria-close-label":"Close","role":"alert"}},_vm._l((_vm.errors),function(error,index){return _c('div',{key:index},[_vm._v(_vm._s(error))])}),0):_vm._e(),_c('form',{on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-3"},[_c('b-field',{attrs:{"custom-class":"is-small","label":"Forum"}},[_c('b-select',{attrs:{"size":"is-small","placeholder":"Select a forum","required":"","expanded":""},model:{value:(_vm.formData.forum),callback:function ($$v) {_vm.$set(_vm.formData, "forum", $$v)},expression:"formData.forum"}},_vm._l((_vm.forumsList),function(forum){return _c('option',{key:forum.id,domProps:{"value":forum.slug}},[_vm._v(_vm._s(forum.title))])}),0)],1)],1),_c('div',{staticClass:"column is-9"},[_c('b-field',{attrs:{"custom-class":"is-small","label":"Topic Title"}},[_c('b-input',{attrs:{"size":"is-small"},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}})],1)],1)]),_c('b-field',{attrs:{"custom-class":"is-small","label":"Share your thoughts"}},[_c('editor',{attrs:{"api-key":"llkyn54j9cm6dvjfak7eywf59bxnopcwyoq8fetp590v7ftf","init":{
                 height: 200,
                 menubar: false,
                 plugins: [
                   'advlist autolink lists link searchreplace visualblocks table paste codesample preview'
                 ],
                 toolbar:
                   'preview | undo redo | formatselect | bold italic backcolor | codesample | \
                   alignleft aligncenter alignright alignjustify | \
                   bullist numlist outdent indent | removeformat',
                 extended_valid_elements: 'a[href|target=_blank]'
               }},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}})],1),_c('b-field',{attrs:{"custom-class":"is-small","label":"Attachments"}},[_c('b-upload',{attrs:{"accept":"image/*","multiple":"","drag-drop":"","expanded":""},model:{value:(_vm.dropFiles),callback:function ($$v) {_vm.dropFiles=$$v},expression:"dropFiles"}},[_c('section',{staticClass:"section",staticStyle:{"padding":"1.5rem"}},[_c('div',{staticClass:"content has-text-centered"},[_c('p',[_c('b-icon',{attrs:{"icon":"upload","size":"is-medium"}})],1),_c('small',[_vm._v("Drop your files here or click to upload")])])])])],1),_c('div',{staticClass:"tags"},_vm._l((_vm.dropFiles),function(file,index){return _c('span',{key:index,staticClass:"tag is-primary"},[_vm._v(" "+_vm._s(file.name)+" "),_c('button',{staticClass:"delete is-small",attrs:{"type":"button"},on:{"click":function($event){return _vm.deleteDropFile(index)}}})])}),0),_c('b-field',[_c('b-checkbox',{model:{value:(_vm.formData.subscribe),callback:function ($$v) {_vm.$set(_vm.formData, "subscribe", $$v)},expression:"formData.subscribe"}},[_vm._v("Get notifications by email for new comments on that topic")])],1),_c('b-button',{attrs:{"size":"is-small","type":"is-primary","native-type":"submit"}},[_vm._v("Submit")])],1),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.loadingForm),callback:function ($$v) {_vm.loadingForm=$$v},expression:"loadingForm"}})],1)]):_c('div',{staticClass:"notification has-text-centered is-success"},[(_vm.isBugsSelected)?_c('div',[_c('h3',{staticClass:"title is-3"},[_vm._v("Thank you!")]),_c('p',[_vm._v("We really appreciate your support. We'll try to look into the problem and will post back our findings as soon as possible.")])]):_c('div',[_c('h3',{staticClass:"title is-3"},[_vm._v("Awesome!")]),_c('p',[_vm._v("We really appreciate your interest in Odd Giants. Hope this will become a good discussion.")])]),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"has-text-centered has-text-weight-bold mt-4"},[_vm._v("If you like what we're doing and want to support us, so we can keep moving, you can become our patron on "),_c('a',{attrs:{"href":"https://www.patreon.com/oddgiants","target":"_blank"}},[_vm._v("PATREON")])])}]

export { render, staticRenderFns }