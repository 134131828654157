import Vue from 'vue';
import VueRouter from 'vue-router';
import VueMeta from 'vue-meta';
import { validateResetPasswordToken } from '@/services/resetPasswordValidator';
import Home from '../views/Home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/PrivacyPolicy.vue'),
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms and Conditions',
    component: () => import(/* webpackChunkName: "terms" */ '../views/TermsConditions.vue'),
  },
  {
    path: '/oddnet',
    name: 'OddNet',
    component: () => import(/* webpackChunkName: "oddnet" */ '../views/OddNet.vue'),
  },
  {
    path: '/feats',
    name: 'Feats',
    component: () => import(/* webpackChunkName: "feats" */ '../views/Feats.vue'),
    children: [
      {
        path: '',
        name: 'Feats Home',
        component: () => import(/* webpackChunkName: "feats/home" */ '../views/Feats/Home.vue'),
      },
      {
        path: ':featId',
        name: 'Feat Details',
        props: true,
        component: () => import(/* webpackChunkName: "feats/details" */ '../views/Feats/FeatDetails.vue'),
      },
    ],
  },
  {
    path: '/forums',
    component: () => import(/* webpackChunkName: "forums" */ '../views/Forums.vue'),
    children: [
      {
        path: '',
        name: 'Forums Home',
        component: () => import(/* webpackChunkName: "forums/home" */ '../views/Forum/Home.vue'),
      },
      {
        path: 'search/:keyword',
        name: 'Forum Search',
        props: true,
        component: () => import(/* webpackChunkName: "forums/search" */ '../views/Forum/Search.vue'),
      },
      {
        path: ':forum',
        name: 'Forum Details',
        props: true,
        component: () => import(/* webpackChunkName: "forums/details" */ '../views/Forum/ForumDetails.vue'),
      },
      {
        path: ':forum/:topic',
        name: 'Topic Details',
        props: true,
        component: () => import(/* webpackChunkName: "forums/topic" */ '../views/Forum/TopicDetails.vue'),
      },
    ],
  },
  {
    path: '/encyclopedia',
    component: () => import(/* webpackChunkName: "encyclopedia" */ '../views/Encyclopedia.vue'),
    children: [
      {
        path: '',
        name: 'Encyclopedia Home',
        component: () => import(/* webpackChunkName: "encyclopedia/home" */ '../views/Encyclopedia/Home.vue'),
      },
      {
        path: 'search/:keyword',
        name: 'Encyclopedia Search',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/search" */ '../views/Encyclopedia/Search.vue'),
      },
      {
        path: 'skills',
        name: 'Encyclopedia Skills',
        component: () => import(/* webpackChunkName: "encyclopedia/skills" */ '../views/Encyclopedia/Skills.vue'),
      },
      {
        path: 'skills/:category',
        name: 'Encyclopedia Skill Category',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/skills/category" */ '../views/Encyclopedia/SkillCategory.vue'),
      },
      {
        path: 'skills/:category/:skillId',
        name: 'Encyclopedia Skill Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/skills/details" */ '../views/Encyclopedia/SkillDetails.vue'),
      },
      {
        path: 'achievements',
        name: 'Encyclopedia Achievements',
        component: () => import(/* webpackChunkName: "encyclopedia/achievements" */ '../views/Encyclopedia/Achievements.vue'),
      },
      {
        path: 'achievements/:category',
        name: 'Encyclopedia Achievement Category',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/achievements/category" */ '../views/Encyclopedia/AchievementCategory.vue'),
      },
      {
        path: 'achievements/:category/:achievementId',
        name: 'Encyclopedia Achievement Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/achievements/details" */ '../views/Encyclopedia/AchievementDetails.vue'),
      },
      {
        path: 'locations',
        name: 'Encyclopedia Locations',
        component: () => import(/* webpackChunkName: "encyclopedia/locations" */ '../views/Encyclopedia/Locations.vue'),
      },
      {
        path: 'locations/:regionId',
        name: 'Encyclopedia Location Region',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/locations/region" */ '../views/Encyclopedia/LocationRegion.vue'),
      },
      {
        path: 'locations/:regionId/:locationId',
        name: 'Encyclopedia Location Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/locations/details" */ '../views/Encyclopedia/LocationDetails.vue'),
      },
      {
        path: 'giants',
        name: 'Encyclopedia Giants',
        component: () => import(/* webpackChunkName: "encyclopedia/giants" */ '../views/Encyclopedia/Giants.vue'),
      },
      {
        path: 'giants/:giantId',
        name: 'Encyclopedia Giant Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/giants/details" */ '../views/Encyclopedia/GiantDetails.vue'),
      },
      {
        path: 'upgrades',
        name: 'Encyclopedia Upgrades',
        component: () => import(/* webpackChunkName: "encyclopedia/upgrades" */ '../views/Encyclopedia/Upgrades.vue'),
      },
      {
        path: 'upgrades/:category',
        name: 'Encyclopedia Upgrade Category',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/upgrades/category" */ '../views/Encyclopedia/UpgradeCategory.vue'),
      },
      {
        path: 'upgrades/:category/:upgradeId',
        name: 'Encyclopedia Upgrade Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/upgrades/details" */ '../views/Encyclopedia/UpgradeDetails.vue'),
      },
      {
        path: 'items',
        name: 'Encyclopedia Items',
        component: () => import(/* webpackChunkName: "encyclopedia/items" */ '../views/Encyclopedia/Items.vue'),
      },
      {
        path: 'items/:category',
        name: 'Encyclopedia Item Category',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/items/category" */ '../views/Encyclopedia/ItemCategory.vue'),
      },
      {
        path: 'items/:category/:itemId',
        name: 'Encyclopedia Item Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/items/details" */ '../views/Encyclopedia/ItemDetails.vue'),
      },
      {
        path: 'inhabitants',
        name: 'Encyclopedia Inhabitants',
        component: () => import(/* webpackChunkName: "encyclopedia/inhabitants" */ '../views/Encyclopedia/Inhabitants.vue'),
      },
      {
        path: 'inhabitants/:category',
        name: 'Encyclopedia Inhabitant Category',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/inhabitants/category" */ '../views/Encyclopedia/InhabitantCategory.vue'),
      },
      {
        path: 'inhabitants/:category/:inhabitantId',
        name: 'Encyclopedia Inhabitant Details',
        props: true,
        component: () => import(/* webpackChunkName: "encyclopedia/inhabitants/details" */ '../views/Encyclopedia/InhabitantDetails.vue'),
      },
    ],
  },
  {
    path: '/downloads',
    name: 'Downloads',
    component: () => import(/* webpackChunkName: "downloads" */ '../views/Downloads.vue'),
  },
  {
    path: '/search/:keyword?',
    name: 'Search Oddlings',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue'),
  },
  {
    path: '/player/:userId',
    name: 'User Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/Player.vue'),
    props: true,
    children: [
      {
        path: '',
        name: 'Player Feed',
        props: true,
        component: () => import(/* webpackChunkName: "profile/home" */ '../views/Player/Home.vue'),
      },
      {
        path: 'feed/:postType/:postId',
        name: 'Single Post',
        props: true,
        component: () => import(/* webpackChunkName: "profile/post" */ '../views/Player/SinglePost.vue'),
      },
      {
        path: 'settings',
        name: 'Player Settings',
        props: true,
        component: () => import(/* webpackChunkName: "profile/settings" */ '../views/Player/Settings.vue'),
      },
      {
        path: 'notifications',
        name: 'Player Notifications',
        props: true,
        component: () => import(/* webpackChunkName: "profile/notifications" */ '../views/Player/Notifications.vue'),
      },
      {
        path: 'skills',
        name: 'Player Skills',
        props: true,
        component: () => import(/* webpackChunkName: "profile/skills" */ '../views/Player/Skills.vue'),
      },
      {
        path: 'achievements',
        name: 'Player Achievements',
        props: true,
        component: () => import(/* webpackChunkName: "profile/achievements" */ '../views/Player/Achievements.vue'),
      },
      {
        path: 'upgrades',
        name: 'Player Upgrades',
        props: true,
        component: () => import(/* webpackChunkName: "profile/upgrades" */ '../views/Player/Upgrades.vue'),
      },
      {
        path: 'feats',
        name: 'Player Feats',
        props: true,
        component: () => import(/* webpackChunkName: "profile/feats" */ '../views/Player/Feats.vue'),
      },
    ],
  },
  {
    beforeEnter: validateResetPasswordToken,
    path: '/password/:token',
    name: 'Reset password',
    component: () => import(/* webpackChunkName: "password" */ '../views/PasswordReset.vue'),
  },
  {
    path: '/unsubscribe/:type/:token',
    name: 'Subscriptions',
    component: () => import(/* webpackChunkName: "subscriptions" */ '../views/Subscription.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  return next();
});

export default router;
