<!--eslint-disable-->
<template>
  <a href="https://www.instagram.com/oddgiantsgame" target="_blank">
    <svg data-name="01-instagram" id="_01-instagram" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" :style="`width: ${size}px;`">
      <rect height="62" rx="6" style="fill:#f55690" width="62" x="1" y="1"/>
      <path d="M57,1H7A6,6,0,0,0,1,7V17A22,22,0,0,0,23,39H41A22,22,0,0,0,63,17V7A6,6,0,0,0,57,1Z" style="fill:#ff7dac"/>
      <path d="M57,1H7A6,6,0,0,0,1,7v4A6,6,0,0,1,7,5H57a6,6,0,0,1,6,6V7A6,6,0,0,0,57,1Z" style="fill:#ffa8c9"/>
      <path d="M57,59H7a6,6,0,0,1-6-6v4a6,6,0,0,0,6,6H57a6,6,0,0,0,6-6V53A6,6,0,0,1,57,59Z" style="fill:#b04269"/>
      <path d="M41.16,28A9.881,9.881,0,0,1,42,32a10,10,0,0,1-20,0,9.881,9.881,0,0,1,.84-4,9.992,9.992,0,0,1,18.32,0Z" style="fill:#f55690"/>
      <path d="M45,11H19a8,8,0,0,0-8,8V45a8,8,0,0,0,8,8H45a8,8,0,0,0,8-8V19A8,8,0,0,0,45,11Zm1,4a3,3,0,1,1-3,3A3,3,0,0,1,46,15ZM42,32a10,10,0,0,1-20,0,9.881,9.881,0,0,1,.84-4,9.992,9.992,0,0,1,18.32,0A9.881,9.881,0,0,1,42,32Z" style="fill:#f55690"/>
      <circle cx="46" cy="18" r="3" style="fill:#fff"/>
      <path d="M45,53H19a8,8,0,0,1-8-8v3a8,8,0,0,0,8,8H45a8,8,0,0,0,8-8V45A8,8,0,0,1,45,53Z" style="fill:#b04269"/>
      <path d="M32,25a9.991,9.991,0,0,1,9.16,6,9.811,9.811,0,0,1,.715,2.505A10.085,10.085,0,0,0,42,32a9.881,9.881,0,0,0-.84-4,9.992,9.992,0,0,0-18.32,0A9.881,9.881,0,0,0,22,32a10.085,10.085,0,0,0,.125,1.505A9.811,9.811,0,0,1,22.84,31,9.991,9.991,0,0,1,32,25Z" style="fill:#b04269"/>
      <path d="M45,54H19a9.011,9.011,0,0,1-9-9V19a9.011,9.011,0,0,1,9-9H45a9.011,9.011,0,0,1,9,9V45A9.011,9.011,0,0,1,45,54ZM19,12a7.008,7.008,0,0,0-7,7V45a7.008,7.008,0,0,0,7,7H45a7.008,7.008,0,0,0,7-7V19a7.008,7.008,0,0,0-7-7Z" style="fill:#fff"/>
      <path d="M32,43A11.013,11.013,0,0,1,21,32a10.835,10.835,0,0,1,.925-4.405,10.993,10.993,0,0,1,20.151,0A10.82,10.82,0,0,1,43,32,11.013,11.013,0,0,1,32,43Zm0-20a9,9,0,0,0-8.244,5.4A8.842,8.842,0,0,0,23,32a9,9,0,0,0,18,0,8.833,8.833,0,0,0-.754-3.595l0-.005A9,9,0,0,0,32,23Z" style="fill:#fff"/>
      <path d="M46,21a2.986,2.986,0,0,1-2.817-2,3,3,0,1,0,5.634,0A2.986,2.986,0,0,1,46,21Z" style="fill:#b04269"/>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'instagram',
  props: {
    size: Number,
  },
};
</script>
