<template>
  <section class="hero is-bold is-dark">
    <div class="hero-body">
      <div class="container">
        <div class="title has-text-centered mt-6" id="socialContentFooter">
          STAY TUNED...
          <br /><br />
          <facebook :size="size" class="mr-6" />
          <discord :size="size" class="mr-6" />
          <instagram :size="size" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Facebook from '../Icons/Facebook.vue';
import Discord from '../Icons/Discord.vue';
import Instagram from '../Icons/Instagram.vue';
import { isDesktop } from '../../services/screen';

export default {
  name: 'stay-tuned',
  components: { Instagram, Discord, Facebook },
  computed: {
    size() {
      return this.isDesktop ? 120 : 50;
    },
  },
  data() {
    return {
      isDesktop: isDesktop(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.social-logo {
  max-width: 100%;
}
</style>
