import { Auth } from './api';

export default class TokenStorage {
  static LOCAL_STORAGE_TOKEN = 'og_store';

  static refreshing = false;

  static isAuthenticated() {
    return this.getToken() !== null;
  }

  static getNewToken() {
    this.refreshing = true;

    return new Promise((resolve, reject) => {
      Auth.refreshToken(this.getRefreshToken(), this.getRemember())
        .then((data) => {
          this.storeToken(data);

          this.refreshing = false;

          resolve(data.access_token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  static storeToken(tokenData) {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, JSON.stringify(tokenData));
  }

  static clear() {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  static getRefreshToken() {
    const tokenData = localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);

    if (!tokenData) {
      return null;
    }

    return JSON.parse(tokenData).refresh_token;
  }

  static getToken() {
    const tokenData = localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);

    if (!tokenData) {
      return null;
    }

    return JSON.parse(tokenData).access_token;
  }

  static getRemember() {
    const tokenData = localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);

    if (!tokenData) {
      return null;
    }

    return JSON.parse(tokenData).remember;
  }
}
