import Vue from 'vue';
import Buefy from 'buefy';
import Api from '@/services/api/api';
import TokenStorage from '@/services/token';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { format, formatDistanceToNow } from 'date-fns';
import VideoBackground from 'vue-responsive-video-background-player';
import VueAnalytics from 'vue-analytics';
import App from './App.vue';
import router from './router';
import { store } from './store/index';

import './styles/main.scss';

import { areCookiesAllowed } from './services/storage';
import { utcDate } from './helpers/utils';

library.add(fas);
Vue.component('vue-fontawesome', FontAwesomeIcon);
Vue.component('video-background', VideoBackground);

Vue.use(Buefy, {
  defaultIconComponent: 'vue-fontawesome',
  defaultIconPack: 'fas',
});

Vue.use(VueAnalytics, {
  id: 'UA-124655662-4',
  router,
  disabled: !areCookiesAllowed(),
});

Vue.config.productionTip = false;

Vue.filter('excerpt', (value, maxLen = 100) => {
  const strippedValue = value
    .replace(/(<([^>]+)>)/gi, '')
    .replace('&nbsp;', '');

  if (strippedValue.length <= maxLen) {
    return strippedValue;
  }

  return `${strippedValue.substr(0, strippedValue.lastIndexOf(' ', maxLen))} ...`;
});

Vue.filter('nl2br', (value) => value.replace(/(?:\r\n|\r|\n)/g, '<br>'));

Vue.filter('standardNumber', (value) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0));

Vue.filter('dateStandard', (value) => format(utcDate(value), 'LLL d, yyyy, HH:mm'));
Vue.filter('dateAgo', (value) => (value === null ? 'no one knows...' : `${formatDistanceToNow(utcDate(value))} ago`));

Vue.filter('ucfirst', (s) => s && s[0].toUpperCase() + s.slice(1));

const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
const waitFor = async (f) => {
  // eslint-disable-next-line no-await-in-loop
  while (!f()) await sleep(1000);
  return f();
};

new Vue({
  router,
  store,
  render: (h) => h(App),
  methods: {
    logoutUser(error) {
      TokenStorage.clear();

      this.$store.dispatch('user/logout');

      if (this.$route.name === 'Player Settings') {
        router.push({ path: `/player/${this.$route.params.userId}` });
      }

      return new Promise((resolve, reject) => {
        reject(error);
      });
    },
  },
  beforeCreate() {
    Api.setRequestInterceptor(
      (config) => {
        if (TokenStorage.isAuthenticated()) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `Bearer ${TokenStorage.getToken()}`;
        }

        return config;
      },
      (error) => Promise.reject(error),
    );
    Api.setResponseInterceptor(
      (response) => response.data,
      async (error) => {
        // Return any error which is not due to authentication back to the calling service
        if (error.response.status !== 401) {
          return new Promise((resolve, reject) => {
            reject(error);
          });
        }

        if (
          !TokenStorage.isAuthenticated()
          || error.config.url.includes('/login/refresh')
          || error.response.message === 'Account is disabled.'
        ) {
          return this.logoutUser(error);
        }

        if (!TokenStorage.refreshing) {
          try {
            await TokenStorage.getNewToken();
          } catch (tokenError) {
            this.logoutUser(tokenError);
          }
        } else {
          await waitFor(() => !TokenStorage.refreshing);
        }

        // Try request again with new token
        const { config } = error;
        config.headers.Authorization = `Bearer ${TokenStorage.getToken()}`;

        return new Promise((resolve, reject) => {
          Api.request(config)
            .then((response) => {
              resolve(response);
            })
            .catch((replayError) => {
              reject(replayError);
            });
        });
      },
    );

    this.$store.dispatch('initialize');
  },
}).$mount('#app');
