<template>
  <div class="is-relative">
    <div v-if="partialFeed && partialFeed.length">
      <div
        v-for="feedItem in partialFeed"
        :key="`${feedItem.user_id}-${feedItem.type}-${feedItem.id}`"
      >
        <FeedItem :feed-item="feedItem" class="mb-5" />
      </div>
    </div>
    <b-loading :is-full-page="false" v-model="loadingFeed"></b-loading>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FeedItem from '@/components/Player/FeedItem.vue';

export default {
  name: 'home-partial-user-feed',
  components: { FeedItem },
  computed: {
    ...mapState('player', ['displayFeed', 'loadingFeed']),
    partialFeed() {
      if (!this.displayFeed.length) {
        return [];
      }

      return this.displayFeed.slice(0, 4);
    },
  },
  mounted() {
    this.loadFeed();
  },
  methods: {
    async loadFeed() {
      await this.$store.dispatch('player/refreshDisplayFeed', { type: 'all' });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
