<template>
  <div
    @mouseover="setAutoplay(false)"
    @mouseleave="setAutoplay(true)"
  >
    <b-carousel-list
      v-model="item"
      :data="featuredPlayers"
      :items-to-show="featuredPlayersToShow"
    >
      <template #item="player">
        <router-link
          class="button is-fullwidth encyclopediaItem"
          :to="{ path: `/player/${player.id}` }"
        >
          <div><player-avatar :user="player" size="medium" /></div>
          <span class="is-size-7">{{ player.name }}</span>
        </router-link>
      </template>
    </b-carousel-list>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import PlayerAvatar from './Avatar.vue';

export default {
  name: 'featured-carousel',
  components: { PlayerAvatar },
  computed: {
    ...mapGetters('global', ['featuredPlayersShuffled']),
    featuredPlayers() {
      return this.featuredPlayersShuffled();
    },
    featuredPlayersToShow() {
      const playerMinWidth = 170;

      return Math.floor(this.windowWidth / playerMinWidth);
    },
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      item: 0,
      interval: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    this.setAutoplay(true);
  },
  beforeDestroy() {
    window.clearInterval(this.interval);
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    setAutoplay(on = true) {
      if (on) {
        this.interval = window.setInterval(() => {
          if (this.item === this.featuredPlayers.length - this.featuredPlayersToShow) {
            this.item = 0;
          } else {
            this.item += 1;
          }
        }, 3500);
      } else {
        window.clearInterval(this.interval);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.carousel-list.has-shadow {
  box-shadow: none;
}
</style>
