<template>
  <div class="modal-card" style="width: auto">
    <section class="modal-card-body">
      <div v-if="!sent">
        <div>
          <h4 class="title is-5">What do you want to discuss?</h4>

          <b-notification
            v-if="errors.length"
            type="is-danger"
            aria-close-label="Close"
            role="alert"
          >
            <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
          </b-notification>

          <form @submit="handleSubmit">
            <div class="columns">
              <div class="column is-3">
                <b-field custom-class="is-small" label="Forum">
                  <b-select v-model="formData.forum" size="is-small" placeholder="Select a forum" required expanded>
                    <option v-for="forum in forumsList" :key="forum.id" :value="forum.slug">{{ forum.title }}</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-9">
                <b-field custom-class="is-small" label="Topic Title">
                  <b-input size="is-small" v-model="formData.title"></b-input>
                </b-field>
              </div>
            </div>

            <b-field custom-class="is-small" label="Share your thoughts">
              <editor
                v-model="formData.text"
                api-key="llkyn54j9cm6dvjfak7eywf59bxnopcwyoq8fetp590v7ftf"
                :init="{
                   height: 200,
                   menubar: false,
                   plugins: [
                     'advlist autolink lists link searchreplace visualblocks table paste codesample preview'
                   ],
                   toolbar:
                     'preview | undo redo | formatselect | bold italic backcolor | codesample | \
                     alignleft aligncenter alignright alignjustify | \
                     bullist numlist outdent indent | removeformat',
                   extended_valid_elements: 'a[href|target=_blank]'
                 }"
              />
            </b-field>

            <b-field custom-class="is-small" label="Attachments">
              <b-upload
                v-model="dropFiles"
                accept="image/*"
                multiple
                drag-drop
                expanded
              >
                <section class="section" style="padding: 1.5rem;">
                  <div class="content has-text-centered">
                    <p>
                      <b-icon icon="upload" size="is-medium"></b-icon>
                    </p>
                    <small>Drop your files here or click to upload</small>
                  </div>
                </section>
              </b-upload>
            </b-field>

            <div class="tags">
              <span
                v-for="(file, index) in dropFiles"
                :key="index"
                class="tag is-primary"
              >
                {{file.name}}
                <button class="delete is-small" type="button" @click="deleteDropFile(index)"></button>
              </span>
            </div>

            <b-field>
              <b-checkbox v-model="formData.subscribe">Get notifications by email for new comments on that topic</b-checkbox>
            </b-field>

            <b-button size="is-small" type="is-primary" native-type="submit">Submit</b-button>
          </form>

          <b-loading :is-full-page="false" v-model="loadingForm"></b-loading>
        </div>
      </div>

      <div v-else class="notification has-text-centered is-success">
        <div v-if="isBugsSelected">
          <h3 class="title is-3">Thank you!</h3>
          <p>We really appreciate your support. We'll try to look into the problem and will post back our findings as soon as possible.</p>
        </div>
        <div v-else>
          <h3 class="title is-3">Awesome!</h3>
          <p>We really appreciate your interest in Odd Giants. Hope this will become a good discussion.</p>
        </div>
        <p class="has-text-centered has-text-weight-bold mt-4">If you like what we're doing and want to support us, so we can keep moving, you can become our patron on <a href="https://www.patreon.com/oddgiants" target="_blank">PATREON</a></p>
      </div>

    </section>
  </div>
</template>

<script>
import { Forum } from '@/services/api';
import { mapGetters, mapState } from 'vuex';
import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'topic-form',
  components: { Editor },
  data() {
    return {
      errors: [],
      dropFiles: [],
      formData: {
        subscribe: true,
        forum: null,
        title: null,
        text: null,
      },
      loadingForm: false,
      sent: false,
    };
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
    ...mapState('forum', ['forums', 'topicFormForum', 'selectedForum', 'selectedTopic']),
    isBugsSelected() {
      return this.formData.forum === 'bugs';
    },
    forumsList() {
      return this.forums.filter((item) => item.slug !== 'announcements');
    },
  },
  mounted() {
    this.formData.forum = this.topicFormForum;
  },
  methods: {
    deleteDropFile(index) {
      this.dropFiles.splice(index, 1);
    },
    handleSubmit(event) {
      event.preventDefault();

      this.errors = [];
      this.loadingForm = true;

      Forum.createTopic(
        this.formData.forum,
        this.formData.title,
        this.formData.text,
        this.formData.subscribe,
        this.dropFiles,
      )
        .then(() => {
          this.sent = true;
          this.$store.dispatch('forum/loadTopics', 1);
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loadingForm = false;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
