<!--eslint-disable-->
<template>
  <a href="https://discord.gg/vK68sHCzt4" target="_blank">
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" :style="`width: ${size}px;`">
      <g data-name="02-discord" id="_02-discord">
        <rect height="62" rx="6" style="fill:#7388d9" width="62" x="1" y="1"/>
        <path d="M57,1H7A6,6,0,0,0,1,7V17A22,22,0,0,0,23,39H41A22,22,0,0,0,63,17V7A6,6,0,0,0,57,1Z" style="fill:#8198f2"/>
        <path d="M57,1H7A6,6,0,0,0,1,7v4A6,6,0,0,1,7,5H57a6,6,0,0,1,6,6V7A6,6,0,0,0,57,1Z" style="fill:#94aaff"/>
        <path d="M57,59H7a6,6,0,0,1-6-6v4a6,6,0,0,0,6,6H57a6,6,0,0,0,6-6V53A6,6,0,0,1,57,59Z" style="fill:#5061a6"/>
        <path d="M36,52l-1-5a18.587,18.587,0,0,0,12-5c-.421.11-8.2,3-15,3s-14.579-2.89-15-3a18.587,18.587,0,0,0,12,5l-1,5a55.746,55.746,0,0,1-16.1-3.093,5.972,5.972,0,0,1-3.894-5.848A117.465,117.465,0,0,1,12.119,17.7a6,6,0,0,1,4.13-4.177A55.127,55.127,0,0,1,29,12a23.189,23.189,0,0,0-11,6c.421-.11,7.2-3.008,14-3a43.061,43.061,0,0,1,14.029,2.972A23.088,23.088,0,0,0,35,12a55.127,55.127,0,0,1,12.751,1.519,6,6,0,0,1,4.13,4.177,117.465,117.465,0,0,1,4.113,25.363A5.972,5.972,0,0,1,52.1,48.907,55.746,55.746,0,0,1,36,52Z" style="fill:#fdfef9"/>
        <circle cx="22" cy="33" r="5" style="fill:#7388d9"/>
        <circle cx="42" cy="33" r="5" style="fill:#7388d9"/>
        <path d="M42,30a5,5,0,0,1,4.9,4,5,5,0,1,0-9.8,0A5,5,0,0,1,42,30Z" style="fill:#5061a6"/>
        <path d="M22,30a5,5,0,0,1,4.9,4,5,5,0,1,0-9.8,0A5,5,0,0,1,22,30Z" style="fill:#5061a6"/>
        <path d="M43.218,15.63A76.447,76.447,0,0,0,35,15c.267.055.789.174,1.482.381a45.828,45.828,0,0,1,9.547,2.591A20.956,20.956,0,0,0,43.218,15.63Z" style="fill:#5061a6"/>
        <path d="M11.9,48.907a5.973,5.973,0,0,1-3.8-4.59c-.036.577-.069,1.156-.1,1.742A5.972,5.972,0,0,0,11.9,51.907,55.746,55.746,0,0,0,28,55l1-5s-.221,0-.595-.027L28,52A55.746,55.746,0,0,1,11.9,48.907Z" style="fill:#5061a6"/>
        <path d="M47,42c-.421.11-8.2,3-15,3s-14.579-2.89-15-3a18.587,18.587,0,0,0,12,5l-.162.811A29.385,29.385,0,0,0,32,48a29.385,29.385,0,0,0,3.162-.189L35,47A18.587,18.587,0,0,0,47,42Z" style="fill:#5061a6"/>
        <path d="M27.623,15.355c.638-.189,1.124-.3,1.377-.355a76.293,76.293,0,0,0-8.14.619A21.265,21.265,0,0,0,18,18,48.8,48.8,0,0,1,27.623,15.355Z" style="fill:#5061a6"/>
        <path d="M52.1,48.907A55.746,55.746,0,0,1,36,52L35.6,49.973C35.221,50,35,50,35,50l1,5a55.746,55.746,0,0,0,16.1-3.093,5.972,5.972,0,0,0,3.894-5.848c-.026-.586-.059-1.165-.095-1.742A5.973,5.973,0,0,1,52.1,48.907Z" style="fill:#5061a6"/>
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'discord',
  props: {
    size: Number,
  },
};
</script>
