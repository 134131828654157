import Api from './api';

export default {
  login: (email, password, remember) => Api.post('login', { email, password, remember }),
  logout: () => Api.post('logout'),
  refreshToken: (token, remember) => Api.post('login/refresh', { token, remember }),
  requestPasswordReset: (email) => Api.post('password/create', { email }),
  validatePasswordToken: (token) => Api.get(`password/find/${token}`),
  resetPassword: (email, password, token) => Api.post('password/reset', { email, password, token }),
};
