import Vue from 'vue';
import { User, Social } from '@/services/api';

const loadSocialFeed = (type, data) => {
  if (type === 'user') {
    return Social.getUserFeed(data.userId, data.available);
  }

  if (type === 'all') {
    return Social.getAllUsersFeed(data.available);
  }

  if (type === 'friends') {
    return null;
  }

  return null;
};

// eslint-disable-next-line import/prefer-default-export
export const player = {
  namespaced: true,
  state: {
    profiles: {},
    displayFeed: [],
    feedDetails: null,
    loadingProfile: false,
    loadingFeed: false,
    loadingLike: false,
  },
  mutations: {
    setProfile(state, profile) {
      Vue.set(state.profiles, profile.id, profile);
    },
    setDisplayFeed(state, feed) {
      state.displayFeed = feed;
    },
    setFeedDetails(state, payload) {
      state.feedDetails = payload;
    },
    setLoadingProfile(state, loading) {
      state.loadingProfile = loading;
    },
    setLoadingFeed(state, loading) {
      state.loadingFeed = loading;
    },
    setLoadingLike(state, loading) {
      state.loadingLike = loading;
    },
  },
  actions: {
    async loadPlayerProfile({ commit, state }, userId) {
      if (state.profiles[userId]) {
        return true;
      }

      commit('setLoadingProfile', true);

      const data = await User.getPlayerProfile(userId);

      commit('setProfile', data);
      commit('setLoadingProfile', false);

      return true;
    },
    async loadPlayerSkills({ commit, state, dispatch }, userId) {
      if (!state.profiles[userId]) {
        await dispatch('loadPlayerProfile', userId);
      }

      if (state.profiles[userId].skills) {
        return true;
      }

      commit('setLoadingFeed', true);
      const data = await User.getPlayerSkills(userId);

      const newProfile = {
        ...state.profiles[userId],
        skills: data,
      };

      commit('setProfile', newProfile);

      commit('setLoadingFeed', false);

      return true;
    },
    async loadPlayerFeats({ commit, state, dispatch }, userId) {
      if (!state.profiles[userId]) {
        await dispatch('loadPlayerProfile', userId);
      }

      if (state.profiles[userId].feats) {
        return true;
      }

      commit('setLoadingFeed', true);
      const data = await User.getPlayerFeats(userId);

      const newProfile = {
        ...state.profiles[userId],
        feats: data,
      };

      commit('setProfile', newProfile);

      commit('setLoadingFeed', false);

      return true;
    },
    async loadPlayerAchievements({ commit, state, dispatch }, userId) {
      if (!state.profiles[userId]) {
        await dispatch('loadPlayerProfile', userId);
      }

      if (state.profiles[userId].achievements) {
        return true;
      }

      commit('setLoadingFeed', true);
      const data = await User.getPlayerAchievements(userId);

      const newProfile = {
        ...state.profiles[userId],
        achievements: data,
      };

      commit('setProfile', newProfile);

      commit('setLoadingFeed', false);

      return true;
    },
    async loadPlayerUpgrades({ commit, state, dispatch }, userId) {
      if (!state.profiles[userId]) {
        await dispatch('loadPlayerProfile', userId);
      }

      if (state.profiles[userId].upgrades) {
        return true;
      }

      commit('setLoadingFeed', true);
      const data = await User.getPlayerUpgrades(userId);

      const newProfile = {
        ...state.profiles[userId],
        upgrades: data,
      };

      commit('setProfile', newProfile);

      commit('setLoadingFeed', false);

      return true;
    },
    async loadSinglePost({ commit }, { postType, postId }) {
      commit('setLoadingFeed', true);

      Social.getSingleFeedItem(postType, postId)
        .then((data) => {
          commit('setDisplayFeed', [data]);
        })
        .finally(() => {
          commit('setLoadingFeed', false);
        });
    },
    refreshCurrentFeed({ state, dispatch }) {
      if (state.feedDetails) {
        dispatch('refreshDisplayFeed', state.feedDetails);
      }
    },
    async refreshDisplayFeed({ commit, dispatch }, payload) {
      commit('setDisplayFeed', []);

      await dispatch('loadDisplayFeed', payload);
    },
    async loadDisplayFeed({ commit, state }, { type, userId }) {
      commit('setLoadingFeed', true);
      commit('setFeedDetails', { type, userId });

      return loadSocialFeed(type, { userId, available: state.displayFeed.length })
        .then((data) => {
          commit('setDisplayFeed', [
            ...state.displayFeed,
            ...data,
          ]);
        })
        .finally(() => {
          commit('setLoadingFeed', false);
        });
    },
    refreshFeedItem({ commit, state }, { oldItem, newItem }) {
      const displayFeed = [
        ...state.displayFeed,
      ];

      const foundDisplayIndex = displayFeed.findIndex((item) => item.type === oldItem.type && item.id === oldItem.id);
      displayFeed[foundDisplayIndex] = newItem;

      commit('setDisplayFeed', displayFeed);
    },
    removeFeedItem({ commit, state }, feedItem) {
      const newDisplayFeed = [];

      state.displayFeed.forEach((item) => {
        if (item.type !== feedItem.type || item.id !== feedItem.id) {
          newDisplayFeed.push(item);
        }
      });

      commit('setDisplayFeed', newDisplayFeed);
    },
    toggleLike({ commit, dispatch }, feedItem) {
      commit('setLoadingLike', true);

      Social.toggleLike(feedItem.type, feedItem.id)
        .then((data) => {
          dispatch('refreshFeedItem', {
            newItem: data,
            oldItem: feedItem,
          });
        })
        .finally(() => {
          commit('setLoadingLike', false);
        });
    },
  },
};
