export const utcDate = (dateString) => new Date(`${dateString} UTC`.replace(/-/g, '/'));

export const isUTCDatetimePassed = (dateString) => {
  const now = new Date();
  const compareDate = utcDate(dateString);

  return compareDate < now;
};

export const isInUTCPeriod = (dateStart, dateEnd) => {
  const now = new Date();

  return utcDate(dateStart) <= now && utcDate(dateEnd) > now;
};
