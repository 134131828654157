<template>
  <footer class="footer">
    <div class="content has-text-centered has-text-black">
      <div>
        <img
          src="/content/logo-vertical.png"
          alt="Odd Giants - a remake of Glitch The Game"
        >
      </div>
      <div class="mt-6 mb-6">
        <router-link :to="{ path: '/forums' }">Forum</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/forums/bugs' }">Bug Reports</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/forums/announcements' }">Announcements</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/oddnet' }">OddNet</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/downloads' }">DOWNLOAD</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/help' }">Help</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/terms-and-conditions' }">Terms and Conditions</router-link>
        <span class="ml-2 mr-2">|</span>
        <router-link :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>
      </div>
      <p>
        © {{ currentYear }} <strong>Odd Giants</strong>
      </p>
      <p>
        Odd Giants is not affiliated, nor endorsed by <a href="https://tinyspeck.com/">Tiny Speck (Slack)</a>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'copyright',
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.footer a {
  display: inline-block;
}
</style>
