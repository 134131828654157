<template>
  <b-tooltip size="is-small" type="is-dark" :label="date | dateStandard">
    <small v-if="small"><b-icon v-if="icon" icon="clock" size="is-small" style="color: #aaa;" /> {{ date | dateAgo }}</small>
    <span v-else><b-icon v-if="icon" icon="clock" size="is-small" style="color: #aaa;" /> {{ date | dateAgo }}</span>
  </b-tooltip>
</template>

<script>
export default {
  name: 'forum-date',
  props: {
    icon: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: true,
    },
    date: String,
  },
};
</script>
