<template>
  <b-input
    v-bind="$attrs"
    ref="resizableTextarea"
    @focus="resize"
    @input="resize"
    style="resize: none; overflow: hidden;"
  />
</template>

<script>
export default {
  name: 'resizable-textarea',
  mounted() {
    this.resize();
  },
  methods: {
    resize() {
      this.$emit('input', this.$refs.resizableTextarea.$refs.textarea.value);
      this.$refs.resizableTextarea.$refs.textarea.style.minHeight = `${this.$refs.resizableTextarea.$refs.textarea.scrollHeight}px`;
    },
  },
};
</script>
