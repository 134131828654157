<template>
  <b-navbar
    fixed-top
    shadow
    centered
  >
    <template #brand>
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img
          src="/content/logo-horizontal.png"
          alt="Odd Giants - a remake of Glitch The Game"
        >
      </b-navbar-item>
    </template>
    <template #start>
      <template v-if="isDesktop">
        <b-navbar-item tag="router-link" :to="{ path: '/oddnet' }">
          <div><img src="/content/icons/yellow-avatar.png" /></div>
          <div>OddNet</div>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/feats' }">
          <div><img src="/content/icons/feat.png" /></div>
          <div>Feats</div>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/forums' }">
          <div><img src="/content/icons/forums.png" /></div>
          <div>Forums</div>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/encyclopedia' }">
          <div><img src="/content/icons/encyclopedia.png" /></div>
          <div>Encyclopedia</div>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/search' }">
          <div><img src="/content/icons/magnifyingglass.png" /></div>
          <div>Search</div>
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/help' }">
          <div><img src="/content/icons/petrock.png" /></div>
          <div>Help</div>
        </b-navbar-item>
      </template>
      <template v-else>
        <b-navbar-item tag="router-link" :to="{ path: '/oddnet' }" class="has-text-primary-dark">OddNet</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/feats' }">Feats</b-navbar-item>
        <b-navbar-dropdown label="Forums">
          <b-navbar-item tag="router-link" :to="{ path: '/forums' }">All Forums</b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/forums/bugs' }">Bug Reports</b-navbar-item>
          <b-navbar-item tag="router-link" :to="{ path: '/forums/announcements' }">Announcements</b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item tag="router-link" :to="{ path: '/encyclopedia' }">Encyclopedia</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/search' }">Search Oddlings</b-navbar-item>
        <b-navbar-item tag="router-link" :to="{ path: '/help' }">Help</b-navbar-item>
      </template>
    </template>

    <template #end>
      <template v-if="!isAuthenticated">
        <b-navbar-item tag="div">
          <div class="buttons">
            <a class="button is-primary" @click="showLogin">
              <b-icon icon="sign-in-alt" />
              <strong>SIGN IN</strong>
            </a>
            <router-link class="button is-success" :to="{ path: '/downloads' }">
              <strong>PLAY!</strong>
            </router-link>
          </div>
        </b-navbar-item>
      </template>
      <template v-if="isAuthenticated">
        <b-dropdown
          position="is-bottom-left"
          append-to-body
          aria-role="list"
          @active-change="notificationDropdownChange"
        >
          <template #trigger>
            <a class="navbar-item profile-dropdown">
              <b-icon icon="bell" size="is-medium" class="notification-icon" :type="unseenNotificationsCount ? 'is-danger' : ''"></b-icon>
              <span v-if="unseenNotificationsCount" class="badge">{{ unseenNotificationsCount }}</span>
            </a>
          </template>
          <template v-if="notifications.length">
            <b-dropdown-item
              aria-role="listitem"
              v-for="notification in notifications"
              :key="notification.id"
              :class="`notification-item ${notification.seen ? 'seen' : 'unseen'}`"
              ref="notificationDropdownItem"
              @click="viewSinglePost(notification)"
            >
              <Notification :notification="notification" popup />
            </b-dropdown-item>
            <b-dropdown-item class="has-text-centered" @click="viewAllNotifications">
              <strong>View all notifications</strong>
            </b-dropdown-item>
          </template>
          <b-dropdown-item v-else class="has-text-centered">
            Nothing new...
          </b-dropdown-item>
        </b-dropdown>
        <b-dropdown position="is-bottom-left" append-to-body aria-role="list" class="mr-3">
          <template #trigger>
            <a class="navbar-item profile-dropdown">
              <player-avatar :user="profile" size="circle" />
              <span class="ml-2">{{ profile.name }}</span>
            </a>
          </template>

          <b-dropdown-item aria-role="listitem" @click="viewProfile">Profile</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="downloadGame">Download game</b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="logout">Logout</b-dropdown-item>
        </b-dropdown>
      </template>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { isDesktop } from '@/services/screen';
import PlayerAvatar from '../Player/Avatar.vue';
import Notification from '../Notifications/Notification.vue';

export default {
  name: 'navigation',
  components: { PlayerAvatar, Notification },
  computed: {
    ...mapState('user', ['notifications']),
    ...mapGetters('user', ['isAuthenticated', 'profile', 'unseenNotificationsCount']),
  },
  data() {
    return {
      isDesktop: isDesktop(),
    };
  },
  methods: {
    notificationDropdownChange(active) {
      if (active) {
        this.$store.dispatch('user/loadNotifications')
          .then(() => {
            this.setNotificationItemsSeen();
          });
      }
    },
    setNotificationItemsSeen() {
      setTimeout(() => {
        this.$store.dispatch('user/seeNotifications');
        this.$refs.notificationDropdownItem.forEach((ref) => {
          ref.$el.classList.remove('unseen');
        });
        setTimeout(() => {
          this.$refs.notificationDropdownItem.forEach((ref) => {
            ref.$el.classList.add('seen');
          });
        }, 2000);
      }, 2000);
    },
    scrollToSocial() {
      document.getElementById('socialContentFooter').scrollIntoView({ behavior: 'smooth' });
    },
    showDemo() {
      this.$store.dispatch('global/toggleDemoModal', true);
    },
    showLogin() {
      this.$store.dispatch('global/toggleLoginModal', true);
    },
    downloadGame() {
      this.$router.push('/downloads');
    },
    viewProfile() {
      this.$router.push(`/player/${this.profile.id}`);
    },
    viewAllNotifications() {
      this.$router.push(`/player/${this.profile.id}/notifications`);
    },
    viewSinglePost(notification) {
      this.$router.push(`/player/${notification.notifiable.user_id}/feed/${notification.notifiable.model}/${notification.notifiable.id}`);
    },
    logout() {
      this.$store.dispatch('user/logout');
    },
  },
};
</script>

<style scoped lang="scss">
.profile-dropdown {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-left: 15px;
  padding-right: 15px;
}

.notification-icon {
  font-size: 1.2rem;
}

.notification-item {
  -webkit-transition: background-color 1s ease-out;
  -moz-transition: background-color 1s ease-out;
  -o-transition: background-color 1s ease-out;
  transition: background-color 1s ease-out;
}

.badge {
  position: absolute;
  bottom: 0;
  right: 5px;
  background-color: #ddd;
  padding: 4px 8px;
  border-radius: 30px;
  font-size: 10px;
  font-weight: bold;
}

.unseen {
  background-color: #ebebeb;
}

.seen {
  -webkit-transition: none;
  -moz-transition: none;
  -o-transition: none;
  transition: none;
}
</style>
