<template>
  <a @click="showPopup">
    <strong>{{ text }}</strong>
  </a>
</template>

<script>
export default {
  name: 'poppable-item-text',
  props: {
    text: String,
    type: String,
    id: String,
    object_id: {
      type: String,
      default: null,
    },
  },
  methods: {
    showPopup() {
      this.$store.dispatch('global/showPopupItem', {
        type: this.type,
        id: this.id,
        object_id: this.object_id,
      });
    },
  },
};
</script>

<style scoped lang="scss">
</style>
