import { Feat } from '@/services/api';

// eslint-disable-next-line import/prefer-default-export
export const feat = {
  namespaced: true,
  state: {
    feats: [],
    currentFeat: null,
    loadingFeats: false,
    loadingCurrentFeat: false,
  },
  mutations: {
    setFeats(state, feats) {
      state.feats = feats;
    },
    setCurrentFeat(state, currentFeat) {
      state.currentFeat = currentFeat;
    },
    setLoadingFeats(state, loading) {
      state.loadingFeats = loading;
    },
    setLoadingCurrentFeat(state, loading) {
      state.loadingCurrentFeat = loading;
    },
  },
  actions: {
    async loadFeats({ commit }) {
      commit('setLoadingFeats', true);

      await Feat.getAllFeats()
        .then((data) => {
          commit('setFeats', data);
        })
        .finally(() => {
          commit('setLoadingFeats', false);
        });
    },
    loadCurrentFeat({ commit }) {
      commit('setLoadingCurrentFeat', true);

      return Feat.getCurrentFeat()
        .then((data) => {
          commit('setCurrentFeat', data);
        })
        .finally(() => {
          commit('setLoadingCurrentFeat', false);
        });
    },
  },

};
