<template>
  <div :class="inlineThumb ? 'image-inline' : ''">
    <img :src="imageSource" />
  </div>
</template>

<script>
import { getEncyclopediaImageUrl } from '../../services/encyclopedia';

export default {
  name: 'item-image',
  props: {
    thumb: Boolean,
    inlineThumb: Boolean,
    type: String,
    id: String,
  },
  computed: {
    imageSource() {
      return getEncyclopediaImageUrl(this.type, this.id, this.inlineThumb || this.thumb);
    },
  },
};
</script>

<style scoped lang="scss">
  .image-inline {
    display: inline-block;

    > img {
      width: 16px;
      height: 16px;
      margin-left: 9px;
      margin-right: 3px;
    }
  }
</style>
