<template>
  <a @click="goToPlayer" :class="`${user.isAdmin ? 'has-text-primary' : ''}${showAvatar ? 'has-text-centered  is-fullwidth' : ''}`">
    <player-avatar v-if="showAvatar" :user="user" :size="size" />
    <span v-if="showName" class="has-text-weight-bold has-text-centered">{{ user.name }}</span>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';
import PlayerAvatar from '../Player/Avatar.vue';

export default {
  name: 'username',
  components: { PlayerAvatar },
  props: {
    showAvatar: {
      type: Boolean,
      default: true,
    },
    showName: {
      type: Boolean,
      default: true,
    },
    user: Object,
    size: {
      type: String,
      default: 'circle',
    },
  },
  computed: {
    ...mapGetters('user', ['isAuthenticated']),
  },
  methods: {
    goToPlayer() {
      this.$router.push({ path: `/player/${this.user.id}` });
      this.$store.dispatch('global/hideAllModals');
    },
  },
};
</script>
