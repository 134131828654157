<template>
  <div>
    <b-modal
      v-model="isLoginModalVisible"
      trap-focus
      :destroy-on-hide="true"
      has-modal-card
      aria-role="dialog"
      aria-label="Sign in Odd Giants"
      aria-modal>
      <login></login>
    </b-modal>

    <b-modal
      v-model="isSignupModalVisible"
      trap-focus
      :destroy-on-hide="true"
      has-modal-card
      aria-role="dialog"
      aria-label="Sign up for Odd Giants"
      aria-modal>
      <signup></signup>
    </b-modal>

    <b-modal
      v-model="isForgotPasswordModalVisible"
      trap-focus
      :destroy-on-hide="true"
      has-modal-card
      aria-role="dialog"
      aria-label="Forgot Password"
      aria-modal>
      <forgot-password></forgot-password>
    </b-modal>

    <b-modal
      v-model="isForumTopicModalVisible"
      trap-focus
      :destroy-on-hide="true"
      can-cancel
      has-modal-card
      aria-role="dialog"
      aria-label="Start a discussion in the forums"
      aria-modal>
      <topic-form />
    </b-modal>

    <b-modal
      v-model="isPopupItemModalVisible"
      trap-focus
      :destroy-on-hide="true"
      can-cancel
      has-modal-card
      aria-role="dialog"
      aria-label="Details"
      aria-modal>
      <popup-item />
    </b-modal>

    <b-modal
      v-model="isLikesModalVisible"
      trap-focus
      :destroy-on-hide="true"
      can-cancel
      has-modal-card
      aria-role="dialog"
      aria-label="Details"
      aria-modal>
      <likes-modal />
    </b-modal>
  </div>
</template>

<script>
import Login from '@/components/Modals/Login.vue';
import TopicForm from '@/components/Forum/TopicForm.vue';
import PopupItem from './PopupItem.vue';
import LikesModal from './LikesModal.vue';
import Signup from './SignUp.vue';
import ForgotPassword from './ForgotPassword.vue';

export default {
  name: 'modal-containers',
  components: {
    ForgotPassword,
    Signup,
    LikesModal,
    PopupItem,
    TopicForm,
    Login,
  },
  computed: {
    isLoginModalVisible: {
      get() {
        return this.$store.state.global.isLoginModalVisible;
      },
      set(value) {
        this.$store.dispatch('global/toggleLoginModal', value);
      },
    },
    isSignupModalVisible: {
      get() {
        return this.$store.state.global.isSignupModalVisible;
      },
      set(value) {
        this.$store.dispatch('global/toggleSignupModal', value);
      },
    },
    isForgotPasswordModalVisible: {
      get() {
        return this.$store.state.global.isForgotPasswordModalVisible;
      },
      set(value) {
        this.$store.dispatch('global/toggleForgotPasswordModal', value);
      },
    },
    isForumTopicModalVisible: {
      get() {
        return this.$store.state.global.isForumTopicModalVisible;
      },
      set(value) {
        this.$store.dispatch('global/toggleForumTopicModal', value);
      },
    },
    isPopupItemModalVisible: {
      get() {
        return this.$store.state.global.isPopupItemModalVisible;
      },
      set(value) {
        this.$store.dispatch('global/togglePopupItemModal', value);
      },
    },
    isLikesModalVisible: {
      get() {
        return this.$store.state.global.isLikesModalVisible;
      },
      set(value) {
        this.$store.dispatch('global/toggleLikesModal', value);
      },
    },
  },
};
</script>
