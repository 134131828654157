import { Auth } from './api';

// eslint-disable-next-line import/prefer-default-export
export const validateResetPasswordToken = (to, from, next) => {
  if (!to.params.token) {
    next(false);
  }

  Auth.validatePasswordToken(to.params.token)
    .then(() => {
      next();
    })
    .catch(() => {
      next(false);
    });
};
