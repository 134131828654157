import { Config, User } from '@/services/api';

const shuffle = (a) => {
  let j; let x; let i;
  // eslint-disable-next-line no-plusplus
  for (i = a.length - 1; i > 0; i--) {
    j = Math.floor(Math.random() * (i + 1));
    x = a[i];
    // eslint-disable-next-line no-param-reassign
    a[i] = a[j]; a[j] = x;
  }
  return a;
};

// eslint-disable-next-line import/prefer-default-export
export const global = {
  namespaced: true,
  state: {
    configs: null,
    featuredPlayers: [],
    isContactModalVisible: false,
    isLoginModalVisible: false,
    isSignupModalVisible: false,
    isForgotPasswordModalVisible: false,
    isForumTopicModalVisible: false,
    isPopupItemModalVisible: false,
    isLikesModalVisible: false,
    popupItem: null,
    likesItem: null,
    loadingConfigs: false,
    loadingGlobal: false,
    loadingFeatured: false,
  },
  mutations: {
    setConfigs(state, data) {
      state.configs = data;
    },
    setFeaturedPlayers(state, data) {
      state.featuredPlayers = data;
    },
    setContactModal(state, isVisible) {
      state.isContactModalVisible = isVisible;
    },
    setLoginModal(state, isVisible) {
      state.isLoginModalVisible = isVisible;
    },
    setSignupModal(state, isVisible) {
      state.isSignupModalVisible = isVisible;
    },
    setForgotPasswordModal(state, isVisible) {
      state.isForgotPasswordModalVisible = isVisible;
    },
    setForumTopicModal(state, isVisible) {
      state.isForumTopicModalVisible = isVisible;
    },
    setPopupItemModal(state, isVisible) {
      state.isPopupItemModalVisible = isVisible;
    },
    setLikesModal(state, isVisible) {
      state.isLikesModalVisible = isVisible;
    },
    setPopupItem(state, item) {
      state.popupItem = item;
    },
    setLikesItem(state, item) {
      state.likesItem = item;
    },
    setLoadingConfigs(state, loading) {
      state.loadingConfigs = loading;
    },
    setLoadingGlobal(state, loading) {
      state.loadingGlobal = loading;
    },
    setLoadingFeatured(state, loading) {
      state.loadingFeatured = loading;
    },
  },
  actions: {
    hideAllModals({ commit }) {
      commit('setContactModal', false);
      commit('setLoginModal', false);
      commit('setSignupModal', false);
      commit('setForgotPasswordModal', false);
      commit('setForumTopicModal', false);
      commit('setPopupItemModal', false);
      commit('setLikesModal', false);
    },
    loadConfigs({ commit }) {
      commit('setLoadingConfigs', true);

      Config.getConfigs()
        .then((data) => {
          commit('setConfigs', data);
        })
        .finally(() => {
          commit('setLoadingConfigs', false);
        });
    },
    loadFeaturedPlayers({ commit }) {
      commit('setLoadingFeatured', true);

      User.getFeatured()
        .then((data) => {
          commit('setFeaturedPlayers', data);
        })
        .finally(() => {
          commit('setLoadingFeatured', false);
        });
    },
    toggleContactModal({ commit }, visible) {
      commit('setContactModal', visible);
    },
    toggleLoginModal({ commit }, visible) {
      commit('setLoginModal', visible);
    },
    toggleSignupModal({ commit }, visible) {
      commit('setSignupModal', visible);
    },
    toggleForgotPasswordModal({ commit }, visible) {
      commit('setForgotPasswordModal', visible);
    },
    toggleForumTopicModal({ commit }, visible) {
      commit('setForumTopicModal', visible);
    },
    togglePopupItemModal({ commit }, visible) {
      commit('setPopupItemModal', visible);
    },
    toggleLikesModal({ commit }, visible) {
      commit('setLikesModal', visible);
    },
    showPopupItem({ commit }, item) {
      commit('setPopupItem', item);
      commit('setPopupItemModal', true);
    },
    showLikes({ commit }, item) {
      commit('setLikesItem', item);
      commit('setLikesModal', true);
    },
  },
  getters: {
    isLoadingConfigs(state) {
      return state.loadingConfigs;
    },
    gameVersion(state) {
      return state.configs && state.configs.version;
    },
    playersCount(state) {
      return state.configs && state.configs.players_count;
    },
    playersCountFloored(state) {
      return state.configs && Math.floor(state.configs.players_count / 10) * 10;
    },
    featuredPlayersShuffled(state) {
      return (limit = null) => {
        const users = shuffle(state.featuredPlayers);

        return limit ? users.slice(0, limit) : users;
      };
    },
  },
};
