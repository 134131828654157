import axios from 'axios';

class Api {
  constructor() {
    this.api = axios.create({
      baseURL: process.env.VUE_APP_API_URL,
      timeout: 30000,
      headers: {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
    this.loaderEnabled = true;
  }

  setRequestInterceptor(configHandler, errorHandler) {
    this.api.interceptors.request.use(configHandler, errorHandler);
  }

  setResponseInterceptor(responseHandler, errorHandler) {
    this.api.interceptors.response.use(responseHandler, errorHandler);
  }

  enableLoader(value) {
    this.loaderEnabled = value;
  }

  isLoaderEnabled() {
    return this.loaderEnabled;
  }

  get(url, params) {
    return this.api.get(url, { params });
  }

  delete(url, body) {
    return this.api.delete(url, { data: body });
  }

  put(url, body) {
    return this.api.put(url, body);
  }

  post(url, body) {
    return this.api.post(url, body);
  }

  request(config) {
    return this.api.request(config);
  }

  formDataPost(url, data) {
    return this.api.post(url, data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }
}

export default new Api();
