import { getCDNImageEncyclopedia } from './cdn';

const isJPG = (type) => type === 'locations'
  || type === 'regions';

// eslint-disable-next-line import/prefer-default-export
export const getEncyclopediaImageUrl = (type, id, thumb = false) => {
  const extension = isJPG(type) ? 'jpg' : 'png';

  return getCDNImageEncyclopedia(`${type}${thumb ? '/thumbs' : ''}/${id}.${extension}`);
};

export const getShrineImageUrl = (variation, id) => getCDNImageEncyclopedia(`inhabitants/npc_shrine_${variation}_${id}.png`);

export const orderEncyclopediaItemsByCategory = (categoriesArray, itemsArray) => {
  const itemsByCategory = {};

  itemsArray.forEach((item) => {
    if (!itemsByCategory[item.category_id]) {
      itemsByCategory[item.category_id] = {
        id: item.category_id,
        name: categoriesArray.find((category) => category.id === item.category_id).name,
        items: [],
      };
    }

    itemsByCategory[item.category_id].items.push(item);
  });

  Object.keys(itemsByCategory).forEach((index) => {
    itemsByCategory[index].items.sort((a, b) => (a.id > b.id ? 1 : -1));
  });

  return Object.values(itemsByCategory);
};
