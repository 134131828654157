import Api from './api';

export default {
  getProfile: () => Api.get('users'),
  addProfile: (data) => Api.post('users', data),
  getFeatured: () => Api.get('users/featured'),
  search: (keyword) => Api.get(`users/search?q=${keyword}`),
  getPlayerProfile: (userId) => Api.get(`users/${userId}`),
  getPlayerSkills: (userId) => Api.get(`users/${userId}/skills`),
  getPlayerAchievements: (userId) => Api.get(`users/${userId}/achievements`),
  getPlayerUpgrades: (userId) => Api.get(`users/${userId}/upgrades`),
  getPlayerFeats: (userId) => Api.get(`users/${userId}/feats`),
  getSettings: () => Api.get('users/settings'),
  updateSettings: (settings) => Api.post('users/settings', settings),
  updatePassword: (currentPassword, newPassword, confirmation) => Api.post('users/password', {
    current_password: currentPassword,
    password: newPassword,
    password_confirmation: confirmation,
  }),
  updateProfile: (data) => Api.post('users/update', data),
};
