<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">Sign in</p>
      <button
        type="button"
        size="is-large"
        class="delete"
        @click="close"
      />
    </header>
    <div class="modal-card" style="width: auto">
      <section class="modal-card-body">
        <b-notification
          v-if="errors.length"
          type="is-danger"
          aria-close-label="Close"
          role="alert"
          class="form-description"
        >
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </b-notification>

        <form @submit="handleLogin">
          <b-field label="Email" custom-class="is-large">
            <b-input type="email" v-model="formData.email" size="is-large"></b-input>
          </b-field>

          <b-field label="Password" custom-class="is-large">
            <b-input type="password" size="is-large" v-model="formData.password" password-reveal></b-input>
          </b-field>

          <b-field>
            <b-checkbox v-model="formData.remember">Remember me</b-checkbox>
          </b-field>

          <div class="columns is-mobile">
            <div class="column ">
              <b-button type="is-primary" native-type="submit" size="is-medium">Sign in</b-button>
            </div>
            <div class="column has-text-right">
              <div class="has-text-weight-bold"><a @click="showSignUp">Don't have an account? Sign Up!</a></div>
              <div class="mt-4"><a @click="showForgotPassword">Forgot you password?</a></div>
            </div>
          </div>
        </form>

        <b-loading :is-full-page="false" v-model="loading"></b-loading>

        <hr class="mt-3" />

        <div class="columns is-mobile">
          <div class="column">
            If you have an account, you can also download the game and play it!
          </div>
          <div class="column has-text-right">
            <a class="button is-success" @click="goToDownloads"><strong>DOWNLOAD Odd Giants</strong></a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Auth } from '@/services/api';
import TokenStorage from '@/services/token';

export default {
  name: 'login',
  data() {
    return {
      errors: [],
      formData: {
        email: null,
        password: null,
        remember: false,
      },
      loading: false,
    };
  },
  methods: {
    goToDownloads() {
      this.$store.dispatch('global/hideAllModals');
      this.$router.push({ path: '/downloads' });
    },
    handleLogin(event) {
      event.preventDefault();

      this.errors = [];
      this.loading = true;

      Auth.login(this.formData.email, this.formData.password, this.formData.remember)
        .then((tokenData) => {
          TokenStorage.storeToken(tokenData);
          this.$store.dispatch('user/loadProfile').then(() => {
            this.$store.dispatch('global/toggleLoginModal', false);
            if (this.$route.path === '/') {
              this.$router.push({ path: '/oddnet' });
            } else if (this.$route.path === '/oddnet') {
              this.$store.dispatch('player/refreshCurrentFeed');
            }
          });
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    close() {
      this.$store.dispatch('global/toggleLoginModal', false);
    },
    showSignUp() {
      this.close();
      this.$store.dispatch('global/toggleSignupModal', true);
    },
    showForgotPassword() {
      this.close();
      this.$store.dispatch('global/toggleForgotPasswordModal', true);
    },
  },
};
</script>
