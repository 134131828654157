<template>
  <div>
    <div class="container is-fluid is-paddingless">
      <div id="gtg"></div>

      <video-background
        v-if="isDesktop"
        :src="videoLink"
        style="height: 100vh;"
        plays-when="canplaythrough"
      >
        <div class="video-background">
          <div class="cta-btn has-text-centered">
            <router-link :to="{ path: '/downloads' }" size="" class="button is-danger is-large glitch-font">
              PLAY WITH OVER {{ playersCountFloored }} ODDLINGS ONLINE!
            </router-link>
          </div>
        </div>
      </video-background>
    </div>

    <div class="container">
      <div class="section">

        <div class="mb-3 mr-4 ml-4">
          <h1 class="title has-text-centered">
            More than {{ playersCountFloored }} players already joined Odd Giants!
          </h1>
          <featured-carousel />
        </div>

        <section class="hero is-bold has-shadow">
          <div class="hero-body">
            <div class="container">
              <div class="columns">
                <div class="column">
                  <h1 class="title has-text-centered">Latest from the world</h1>
                  <HomePartialUserFeed />
                  <div class="has-text-centered has-text-weight-bold">
                    <router-link to="/oddnet">Check out everything in OddNet</router-link>
                  </div>
                </div>
                <div class="column">
                  <div class="custom-panel custom-panel-grey">
                    <h1 class="title has-text-centered">Remember Glitch The Game?</h1>
                    <h2 class="subtitle mt-6">
                      We're a small team of developers and artists, giving our best to bring back the beauty of <a href="http://glitchthegame.com" target="_blank">Glitch the Game.</a> If you're familiar with the game, we are sure you are as nostalgic as we are.
                    </h2>
                    <h2 class="subtitle mt-6">
                      If you're not - it's a
                      <strong>ONLINE MULTIPLAYER GAME</strong>,
                      featuring a lot of cool stuff, emphasizing on the social aspect and on your explorer spirit.
                    </h2>
                    <h2 class="subtitle mt-6">
                      To make things happen, we decided we can use the help of <a href="https://www.patreon.com/oddgiants" target="_blank">Patreon</a>, so that we cover the expenses for the underlying architecture at least! We're willing to invest our time for development just for fun.
                    </h2>

                    <div class="columns mt-6">
                      <div class="column has-text-centered">
                        <a href="https://www.patreon.com/oddgiants" target="_blank">
                          <b>Support us on Patreon and help us<br />bring back the world of UR!</b><br />
                          <img class="img-fluid" style="width: 250px; height: auto;" src="/content/Patreon_Navy.png" />
                        </a>
                      </div>
                      <div class="column">
                        <b-button size="is-medium" type="is-danger" expanded @click="showSignup">Sign Up to Play!</b-button>
                        <b-button
                          tag="router-link"
                          :to="{ path: '/downloads' }"
                          class="mt-3"
                          size="is-medium"
                          type="is-primary"
                          expanded
                        >
                          Try the game now!
                        </b-button>
                        <small>* Note, this is still an early alpha version of the game, intended to show basic game mechanics and graphics.</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="hero is-bold is-light has-shadow">
          <div class="hero-body">
            <div class="container">
              <h1 class="title has-text-centered">
                I want to know more!
              </h1>
              <h2 class="subtitle mt-6">
                Glitch was a popular and very odd in a good sense Social MMO. Featured tons of interesting quests and tons
                of beautiful areas to explore, lots of secrets to find out, complex crafting and a lot a lot more.
              </h2>
            </div>
          </div>
        </section>

        <section class="mt-6">
          <div class="container is-fluid">
            <h1 class="title has-text-centered">Never played Glitch? Let us show you what is it all about!</h1>

            <div class="tile is-ancestor mt-6">
              <article class="tile is-child box has-text-centered">
                <p class="subtitle">Explore the vast world of over <b>1300</b> different streets!</p>

                <img class="is-fullwidth" src="/content/locations/1.jpg" />
                <img class="is-fullwidth" src="/content/locations/2.jpg" />
                <img class="is-fullwidth" src="/content/locations/3.jpg" />
                <img class="is-fullwidth" src="/content/locations/4.jpg" />
                <img class="is-fullwidth" src="/content/locations/5.jpg" />
              </article>

              <div class="tile is-vertical is-parent">
                <article class="tile is-child box">
                  <p class="subtitle has-text-centered">Earn over <b>600</b> achievements!</p>

                  <div class="columns mt-6">
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/exporer-achievement-1.png" data-toggle="tooltip"
                           data-placement="top" title="Visited every location in Groddle Heights. Including a couple of secret ones." />
                    </div>
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/exporer-achievement-2.png" data-toggle="tooltip"
                           data-placement="top" title="Visited every location in Ix" />
                    </div>
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/harvesting-achievement-1.png" data-toggle="tooltip"
                           data-placement="top" title="Caught 147 perfectly perfect jars of Fireflies" />
                    </div>
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/cultivation-achievement-1.png" data-toggle="tooltip"
                           data-placement="top" title="Drew more than 1009 iMG per day in Stipend" />
                    </div>
                  </div>
                </article>

                <article class="tile is-child box">
                  <p class="subtitle has-text-centered">Find all the <b>NPC's</b> and see what they have for you!</p>

                  <div class="columns">
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/vendors/helga.gif" data-toggle="tooltip" data-placement="top"
                           title="Helga" />
                    </div>
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/vendors/mealvendor.gif" data-toggle="tooltip"
                           data-placement="top" title="Meal Vendor" />
                    </div>
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/vendors/horseman.gif" data-toggle="tooltip" data-placement="top"
                           title="Forehorseman" />
                    </div>
                  </div>
                </article>

                <article class="tile is-child box">
                  <p class="subtitle has-text-centered mt-6">
                    Explore the <b>animals</b>, and interact with them. They might tell you something you want to
                    know!
                  </p>

                  <div class="columns">
                    <div class="column has-text-centered">
                      <img
                        class="img-fluid"
                        src="/content/animals/chicken.gif"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="During, I believe, the eleventy-millennia war, whole clusters of chickens were
                  clustered together and used as projectiles in a heated argument between Tii and Zille.
                  The mess. Oh, the mess."
                      />
                    </div>
                    <div class="column has-text-centered">
                      <img
                        class="img-fluid"
                        src="/content/animals/piggy.gif"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Or nineteeth. Whatever. Whichever of those was the totally righteous one.
                  In the dying days of THAT era, I heard the most dedicated of the Potians wiped out
                  the entire world stock of hooch before the party was halfway done."
                      />
                    </div>
                    <div class="column has-text-centered">
                      <img class="img-fluid" src="/content/animals/fox.gif" data-toggle="tooltip" data-placement="top"
                           title=".." />
                    </div>
                    <div class="column has-text-centered">
                      <img
                        class="img-fluid"
                        src="/content/animals/butterfly_idle.gif"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="OMG. You remember that global ice-storm thing? When all the bubble trees got like totally
                   frozened-up? And people started that major icebubble fight? But the bubbles were the size of
                   everyone's heads and as hard as, like, rock? And then it got like rilly tragic rilly quickly?"
                      />
                    </div>
                  </div>
                </article>
              </div>
            </div>

            <div class="title has-text-centered mt-6">
              And a lot, lot more!
            </div>

            <div class="has-text-centered mt-6">
              <a href="https://www.patreon.com/oddgiants" target="_blank"><img src="/content/supportpatreon.png" /></a>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import FeaturedCarousel from '@/components/Player/FeaturedCarousel.vue';
import { isDesktop } from '@/services/screen';
import HomePartialUserFeed from '../components/Home/PartialUserFeed.vue';

export default {
  name: 'Home',
  components: { HomePartialUserFeed, FeaturedCarousel },
  data() {
    return {
      isDesktop: isDesktop(),
    };
  },
  computed: {
    ...mapGetters('global', ['playersCountFloored']),
    ...mapGetters('user', ['isAuthenticated']),
    videoLink() {
      return `${process.env.VUE_APP_CDN_URL}videos/trailer.mp4`;
    },
  },
  created() {
    if (this.isAuthenticated) {
      this.$router.push({ path: '/oddnet' });
    }
  },
  methods: {
    showSignup() {
      this.$store.dispatch('global/toggleSignupModal', true);
    },
    showDemo() {
      this.$store.dispatch('global/toggleDemoModal', true);
    },
  },
};
</script>

<style scoped lang="scss">
.main-content {
  position: relative;
  background-color: white;
}

.video-background{
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  div.cta-btn {
    margin-top: 100px;
  }
}

</style>
