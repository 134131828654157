<template>
  <div>
    <b-notification
      v-for="announcement in unseenAnnouncements"
      :key="announcement.id"
      has-icon
      type="is-warning"
      aria-close-label="Understood"
      role="alert"
      class="gameAnnouncement"
      @close="storeAnnouncementSeen(announcement.id)"
    >
      <strong>{{ announcement.title }}</strong><br />
      <p>{{ announcement.text }}</p>
    </b-notification>
  </div>
</template>

<script>
import { Announcement } from '@/services/api';

export default {
  name: 'game-announcement',
  data() {
    return {
      announcements: [],
    };
  },
  computed: {
    unseenAnnouncements() {
      const stored = JSON.parse(localStorage.getItem('announcements')) || [];

      return this.announcements.filter((item) => stored.indexOf(item.id) < 0);
    },
  },
  mounted() {
    Announcement.getAllAnnouncements()
      .then((data) => {
        this.announcements = data;
      });
  },
  methods: {
    storeAnnouncementSeen(id) {
      const stored = JSON.parse(localStorage.getItem('announcements')) || [];

      if (!stored || stored.indexOf(id) < 0) {
        stored.push(id);
      }

      localStorage.setItem('announcements', JSON.stringify(stored));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.gameAnnouncement {
  z-index: 99;
  position: fixed;
  top: 80px;
  right: 10px;
}
</style>
