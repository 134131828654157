import { Encyclopedia } from '@/services/api';

// eslint-disable-next-line import/prefer-default-export
export const encyclopedia = {
  namespaced: true,
  state: {
    categories: [],
    skills: [],
    achievements: [],
    locations: [],
    upgrades: [],
    items: [],
    inhabitants: [],
    skill: null,
    achievement: null,
    location: null,
    giant: null,
    upgrade: null,
    item: null,
    inhabitant: null,
    loadingCategories: false,
    loadingItems: false,
  },
  mutations: {
    setCategories(state, data) {
      state.categories = data;
    },
    setSkills(state, data) {
      state.skills = data;
    },
    setAchievements(state, data) {
      state.achievements = data;
    },
    setLocations(state, data) {
      state.locations = data;
    },
    setUpgrades(state, data) {
      state.upgrades = data;
    },
    setItems(state, data) {
      state.items = data;
    },
    setInhabitants(state, data) {
      state.inhabitants = data;
    },
    setSkill(state, data) {
      state.skill = data;
    },
    setAchievement(state, data) {
      state.achievement = data;
    },
    setLocation(state, data) {
      state.location = data;
    },
    setGiant(state, data) {
      state.giant = data;
    },
    setUpgrade(state, data) {
      state.upgrade = data;
    },
    setItem(state, data) {
      state.item = data;
    },
    setInhabitant(state, data) {
      state.inhabitant = data;
    },
    setLoadingCategories(state, loading) {
      state.loadingCategories = loading;
    },
    setLoadingItems(state, loading) {
      state.loadingItems = loading;
    },
  },
  actions: {
    loadCategories({ commit, state }) {
      if (state.categories.length > 0) {
        return;
      }

      commit('setLoadingCategories', true);

      Encyclopedia.getAllCategories()
        .then((data) => {
          commit('setCategories', data);
        })
        .finally(() => {
          commit('setLoadingCategories', false);
        });
    },
    loadSkills({ commit }, category) {
      commit('setLoadingItems', true);

      Encyclopedia.getSkillsByCategory(category)
        .then((data) => {
          commit('setSkills', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadSkillDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getSkill(id)
        .then((data) => {
          commit('setSkill', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadAchievements({ commit }, category) {
      commit('setLoadingItems', true);

      Encyclopedia.getAchievementsByCategory(category)
        .then((data) => {
          commit('setAchievements', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadAchievementDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getAchievement(id)
        .then((data) => {
          commit('setAchievement', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadLocations({ commit }, category) {
      commit('setLoadingItems', true);

      Encyclopedia.getLocationsByRegion(category)
        .then((data) => {
          commit('setLocations', data.filter((location) => !location.label.includes(' Start')));
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadLocationDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getLocation(id)
        .then((data) => {
          const connections = [
            ...data.connections_from,
            ...data.connections_to,
          ]
            .filter((location) => location.region_id)
            .filter((location) => !location.label.includes(' Start'))
            .reduce((locationsByRegion, location) => {
              const key = location.region_id;

              // eslint-disable-next-line no-param-reassign
              locationsByRegion[key] = [...locationsByRegion[key] || []].concat(location);

              return locationsByRegion;
            }, {});

          commit('setLocation', {
            ...data,
            connections,
          });
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadGiantDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getGiant(id)
        .then((data) => {
          commit('setGiant', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadUpgrades({ commit }, category) {
      commit('setLoadingItems', true);

      Encyclopedia.getUpgradesByCategory(category)
        .then((data) => {
          commit('setUpgrades', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadUpgradeDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getUpgrade(id)
        .then((data) => {
          commit('setUpgrade', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadItems({ commit }, category) {
      commit('setLoadingItems', true);

      Encyclopedia.getItemsByCategory(category)
        .then((data) => {
          commit('setItems', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadItemDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getItem(id)
        .then((data) => {
          commit('setItem', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadInhabitants({ commit }, category) {
      commit('setLoadingItems', true);

      Encyclopedia.getInhabitantsByCategory(category)
        .then((data) => {
          commit('setInhabitants', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
    loadInhabitantDetails({ commit }, id) {
      commit('setLoadingItems', true);

      Encyclopedia.getInhabitant(id)
        .then((data) => {
          commit('setInhabitant', data);
        })
        .finally(() => {
          commit('setLoadingItems', false);
        });
    },
  },
};
