<template>
  <div id="app">
    <navigation></navigation>
    <game-announcement />
    <router-view/>
    <div class="container is-fluid is-paddingless">
      <div class="main-content">
        <stay-tuned></stay-tuned>
        <copyright></copyright>
      </div>
    </div>
    <modal-containers></modal-containers>
    <cookie-consent v-if="shouldShowCookieConsent" @consent="cookieConsent = true" />
    <b-loading :is-full-page="true" :active="isInitializing || loadingGlobal"></b-loading>
  </div>
</template>

<script>
import Navigation from '@/components/Layout/Navigation.vue';
import Copyright from '@/components/Layout/Copyright.vue';
import ModalContainers from '@/components/Modals/ModalContainers.vue';
import { mapGetters, mapState } from 'vuex';
import StayTuned from '@/components/Layout/StayTuned.vue';
import GameAnnouncement from '@/components/Layout/GameAnnouncement.vue';
import { hasChosenCookieOption } from './services/storage';
import CookieConsent from './components/Layout/CookieConsent.vue';

export default {
  components: {
    CookieConsent,
    GameAnnouncement,
    StayTuned,
    ModalContainers,
    Copyright,
    Navigation,
  },
  computed: {
    ...mapGetters(['isInitializing']),
    ...mapState('global', ['loadingGlobal']),
    shouldShowCookieConsent() {
      if (this.cookieConsent) {
        return false;
      }

      return !hasChosenCookieOption();
    },
  },
  data() {
    return {
      cookieConsent: false,
    };
  },
  metaInfo: {
    title: 'Odd Giants',
    titleTemplate: '%s | Odd Giants Game, Glitch Remake',
    htmlAttrs: {
      lang: 'en-US',
    },
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Odd Giants Game - developing a new social MMORPG bringing back the beauty of Glitch the Game, one of the best massive multiplayer online games ever',
      },
      {
        vmid: 'keywords',
        name: 'keywords',
        content: 'odd, giants, glitch, game, remake, multiplayer, online, mmorpg',
      },
    ],
  },
};
</script>
