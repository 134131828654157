<template>
  <div>
    <header class="modal-card-head">
      <p class="modal-card-title">Forgot Password</p>
      <button
        type="button"
        class="delete"
        @click="close"
      />
    </header>
    <div class="modal-card" style="width: auto">
      <section class="modal-card-body">
        <b-notification
          v-if="errors.length"
          type="is-danger"
          aria-close-label="Close"
          role="alert"
        >
          <div v-for="(error, index) in errors" :key="index">{{ error }}</div>
        </b-notification>

        <b-notification
          v-if="forgotSuccess"
          type="is-success"
          aria-close-label="Close"
          role="alert"
          class="form-description"
        >
          You should receive an email with a reset password link on the address you have submitted,
          if we find a match in our database.
          Please follow the instructions in the email to reset your password.
          <br />
          <div class="mt-3 has-text-weight-bold"><a @click="showLogin">Go to Sign-in</a></div>
        </b-notification>
        <div v-else>
          <div class="form-description mt-3 mb-3">
            <small>Please submit the email address that you have registered with and you will get a reset password link.</small>
          </div>

          <form @submit="handleForgotPassword">
            <b-field label="Email" custom-class="is-large">
              <b-input type="email" v-model="formData.email" size="is-large"></b-input>
            </b-field>

            <div class="columns">
              <div class="column">
                <b-button type="is-primary" native-type="submit" size="is-medium">Reset my password</b-button>
              </div>
              <div class="column">
                <div class="mt-3 has-text-weight-bold has-text-right"><a @click="showLogin">Back to Sign-in</a></div>
              </div>
            </div>
          </form>
        </div>

        <b-loading :is-full-page="false" v-model="loading"></b-loading>

        <hr class="mt-3" />

        <div class="columns is-mobile">
          <div class="column">
            If you have an account, you can also download the game and play it!
          </div>
          <div class="column has-text-right">
            <a class="button is-success" @click="goToDownloads"><strong>DOWNLOAD Odd Giants</strong></a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Auth } from '@/services/api';

export default {
  name: 'forgot-password',
  data() {
    return {
      errors: [],
      forgotPassword: false,
      forgotSuccess: false,
      formData: {
        email: null,
        password: null,
        remember: false,
      },
      loading: false,
    };
  },
  methods: {
    close() {
      this.$store.dispatch('global/toggleForgotPasswordModal', false);
    },
    goToDownloads() {
      this.close();
      this.$router.push({ path: '/downloads' });
    },
    handleForgotPassword(event) {
      event.preventDefault();

      this.errors = [];
      this.loading = true;

      Auth.requestPasswordReset(this.formData.email)
        .then(() => {
          this.forgotSuccess = true;
          this.forgotPassword = false;
        })
        .catch((error) => {
          const responseError = error.response.data;

          // Validation errors
          if (responseError.errors) {
            Object.keys(responseError.errors).forEach((key) => {
              this.errors.push(responseError.errors[key][0]);
            });

            // General error
          } else {
            this.errors.push('Sorry :( Something went wrong... please try again later');
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showLogin() {
      this.close();
      this.$store.dispatch('global/toggleLoginModal', true);
    },
  },
};
</script>

<style scoped lang="scss">
.form-description {
  max-width: 500px;
}
</style>
