import { Auth, User, Notification } from '@/services/api';
import TokenStorage from '@/services/token';

// eslint-disable-next-line import/prefer-default-export
export const user = {
  namespaced: true,
  state: {
    profile: null,
    notifications: [],
    loadingNotifications: false,
  },
  mutations: {
    setProfile(state, profile) {
      state.profile = profile;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
    setLoadingNotifications(state, loading) {
      state.loadingNotifications = loading;
    },
  },
  actions: {
    initialize({ dispatch }) {
      if (TokenStorage.isAuthenticated()) {
        dispatch('loadProfile');
      }
    },
    loadNotifications({ commit }, limit = 5) {
      commit('setLoadingNotifications', true);

      return Notification.getNotifications(limit)
        .then((data) => {
          commit('setNotifications', data);
        })
        .finally(() => {
          commit('setLoadingNotifications', false);
        });
    },
    seeNotifications({ state, dispatch }) {
      const ids = state.notifications.filter((item) => !item.seen).map((item) => item.id);

      if (ids.length) {
        Notification.seeNotifications(ids);
        dispatch('loadNotifications', state.notifications.length < 5 ? 5 : state.notifications.length);
      }
    },
    loadProfile({ commit, dispatch }) {
      commit('global/setLoadingGlobal', true, { root: true });

      return User.getProfile()
        .then((data) => {
          commit('setProfile', data);
          dispatch('loadNotifications');
        })
        .finally(() => {
          commit('global/setLoadingGlobal', false, { root: true });
        });
    },
    logout({ commit }) {
      commit('global/setLoadingGlobal', true, { root: true });

      if (TokenStorage.isAuthenticated()) {
        Auth.logout().finally(() => {
          TokenStorage.clear();
          commit('setProfile', null);
          commit('global/setLoadingGlobal', false, { root: true });
          window.location.reload();
        });
      } else {
        TokenStorage.clear();
        commit('setProfile', null);
        commit('global/setLoadingGlobal', false, { root: true });
      }
    },
  },
  getters: {
    isAuthenticated(state) {
      return state.profile !== null;
    },
    isLoadingProfile(state) {
      return state.loadingProfile;
    },
    profile(state) {
      return state.profile;
    },
    unseenNotificationsCount(state) {
      return state.notifications.filter((item) => item.seen === 0).length;
    },
  },
};
