<!--eslint-disable-->
<template>
  <a href="https://www.facebook.com/OddGiants" target="_blank">
    <svg viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg" :style="`width: ${size}px;`">
      <g data-name="04-facebook" id="_04-facebook">
        <rect height="62" rx="6" style="fill:#5b79b8" width="62" x="1" y="1"/>
        <path d="M57,1H7A6,6,0,0,0,1,7V17A22,22,0,0,0,23,39H41A22,22,0,0,0,63,17V7A6,6,0,0,0,57,1Z" style="fill:#6688cf"/>
        <path d="M57,1H7A6,6,0,0,0,1,7v4A6,6,0,0,1,7,5H57a6,6,0,0,1,6,6V7A6,6,0,0,0,57,1Z" style="fill:#739ae8"/>
        <path d="M57,59H7a6,6,0,0,1-6-6v4a6,6,0,0,0,6,6H57a6,6,0,0,0,6-6V53A6,6,0,0,1,57,59Z" style="fill:#4462a3"/>
        <rect height="4" style="fill:#aeb2d4" width="10" x="30" y="59"/>
        <path d="M43,21H54V11H42A12,12,0,0,0,30,23v6H22V39h8V59H40V39H52l2-10H40V24A3,3,0,0,1,43,21Z" style="fill:#fdfef9"/>
        <rect height="3" style="fill:#4462a3" width="8" x="22" y="39"/>
        <polygon points="40 39 40 42 52 42 54 32 53.4 32 52 39 40 39" style="fill:#4462a3"/>
        <path d="M40,24v3a3,3,0,0,1,3-3H54V21H43A3,3,0,0,0,40,24Z" style="fill:#4462a3"/>
      </g>
    </svg>
  </a>
</template>

<script>
export default {
  name: 'facebook',
  props: {
    size: Number,
  },
};
</script>
