<template>
  <div class="modal-card" style="width: auto">
    <b-loading :is-full-page="false" v-model="loading"></b-loading>
    <section class="modal-card-body" v-if="item">
      <div class="columns">
        <div class="column is-3 has-text-centered">
          <ItemImage
            v-if="hasPopupImage"
            class="itemImageBig"
            :type="`${popupItem.type}s`"
            :id="popupItem.id"
          />
          <div v-if="hasIcon">
            <b-icon :icon="itemIcon" class="item-icon"></b-icon>
          </div>
        </div>
        <div class="column is-9">
          <h4 class="title is-4">{{ item.title || item.name }}</h4>
          <div class="mt-5" v-html="item.description || item.text"></div>
          <div class="mt-5">
            <b-button type="is-dark" size="is-small" @click="goToDetails">View in {{ isForum ? 'Forums' : 'Encyclopedia' }}</b-button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import { feedItemHasIcon, feedItemHasPopupImage, feedItemIcon } from '@/services/feed';
import { Encyclopedia, Forum } from '@/services/api';
import ItemImage from '../Encyclopedia/ItemImage.vue';

export default {
  name: 'popup-item',
  components: { ItemImage },
  data() {
    return {
      loading: false,
      item: null,
    };
  },
  computed: {
    ...mapState('global', ['popupItem']),
    hasIcon() {
      return feedItemHasIcon(this.popupItem.type);
    },
    hasPopupImage() {
      return feedItemHasPopupImage(this.popupItem.type);
    },
    itemIcon() {
      return feedItemIcon(this.popupItem.type);
    },
    isForum() {
      return ['forum_forum', 'forum_topic', 'forum_comment'].includes(this.popupItem.type);
    },
  },
  mounted() {
    this.loading = true;
    this.getItem()
      .then((data) => {
        this.item = data;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getItem() {
      switch (this.popupItem.type) {
        case 'skill':
          return Encyclopedia.getSkillBrief(this.popupItem.id);
        case 'achievement':
          return Encyclopedia.getAchievementBrief(this.popupItem.id);
        case 'upgrade':
          return Encyclopedia.getUpgradeBrief(this.popupItem.id);
        case 'forum_topic':
          return Forum.getTopicDetails(this.popupItem.object_id, this.popupItem.id);
        case 'forum_forum':
          return Forum.getForumDetails(this.popupItem.id);
        default:
          return null;
      }
    },
    goToDetails() {
      if (this.popupItem.type === 'forum_topic') {
        this.$router.push({ path: `/forums/${this.popupItem.object_id}/${this.item.id}` });
      } else if (this.popupItem.type === 'forum_forum') {
        this.$router.push({ path: `/forums/${this.popupItem.id}` });
      } else {
        this.$router.push({ path: `/encyclopedia/${this.popupItem.type}s/${this.item.category_id}/${this.item.id}` });
      }
      this.$store.dispatch('global/togglePopupItemModal', false);
    },
  },
};
</script>

<style lang="scss">
.item-icon {
  font-size: 4rem;
  color: #d3d3d3;

  &.icon {
    width: 4rem;
    height: 4rem;
  }
}
</style>
