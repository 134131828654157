<template>
  <div class="cookie-consent">
    <b-message type="is-info" has-icon>
      <div class="columns">
        <div class="column is-9">
          <small>
            Odd Giants use cookies for analytics purposes in order to provide better service!<br />
            Make sure to read our <router-link :to="{ path: '/privacy-policy' }">Privacy Policy</router-link>!
          </small>
        </div>
        <div class="column has-text-right">
          <b-button @click="allowCookies" type="is-success" size="is-small">Accept</b-button>
          <b-button @click="rejectCookies" size="is-small" class="ml-2">Reject</b-button>
        </div>
      </div>
    </b-message>
  </div>
</template>

<script>
import { setCookieConsent } from '../../services/storage';

export default {
  name: 'cookie-consent',
  methods: {
    allowCookies() {
      setCookieConsent(true);
      this.$ga.enable();
      this.$emit('consent');
    },
    rejectCookies() {
      setCookieConsent(false);
      this.$emit('consent');
      this.$ga.disable();
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.cookie-consent {
  position: fixed;
  bottom: 0;
  width: 100%;

  .columns {
    margin: 0;
  }
}
</style>
